import * as actions from './actions';
import * as helper from './../scripts/helper';
import * as config from './../requests/config';
import * as settings from './../scripts/settings';
const initialState = () => {
  return {
    loggedIn: sessionStorage.loggedIn && sessionStorage.loggedIn.toString().toLowerCase() === 'true' ? true : false,
    tokenIsValid: sessionStorage.tokenIsValid && sessionStorage.tokenIsValid.toString().toLowerCase() === 'true' ? true : false,
    redirecting: sessionStorage.redirecting && sessionStorage.redirecting.toString().toLowerCase() === 'true' ? true : false,
    requestToken: false,
    serviceLoading: true,
    restartSession: sessionStorage.restartSession && sessionStorage.restartSession.toString().toLowerCase() === 'true' ? true : false,
    hasDevices: sessionStorage.hasDevices && sessionStorage.hasDevices.toString().toLowerCase() === 'true' ? true : false,
    // MJ: Pascal Case used below to align with API payloads
    ServiceId: sessionStorage.ServiceId,
    ServiceNumber: sessionStorage.ServiceNumber,
    UserId: sessionStorage.UserId,
    PBXUserID: sessionStorage.PBXUserID,
    AccountNumber: sessionStorage.AccountNumber,
    DirectoryNumber: sessionStorage.DirectoryNumber,
    LocationAccountNumber: sessionStorage.LocationAccountNumber,
    LocationAddress: sessionStorage.LocationAddress,
    beta_access_denied: sessionStorage.beta_access_denied,
    access_denied: sessionStorage.access_denied,
    loadingAccessToken: true,
    isSelectedServiceIpTrunking: false,
    themes_loaded: false,
    permissionsRetrieved: false,
    isMspLogin: (sessionStorage.isMspLogin && sessionStorage.isMspLogin.toString() === 'true' ? true : false) || false,
    mspLoginData: {},
    //validMfa: sessionStorage.validMfa && sessionStorage.validMfa.toString().toLowerCase() === 'true' ? true : false,
    validMfa: null,
    // cl_call_type: action.cl_call_type,
    // cl_status_type: action.cl_status_type,
    // cl_call_category: action.cl_call_category,
    // cl_from_date: action.cl_from_date,
    // cl_to_date: action.cl_to_date
  }
}

const reducer = (state = initialState(), action) => {
  // set and use constants in store/actions.js to avoid silently failing typo errors
  switch (action.type) {
    // set when login button is clicked
    case actions.REDIRECTING:
      return {
        ...state,
        redirecting: true,
      }
    case actions.START_LOGIN:
      sessionStorage.setItem('timer', settings.loginTimeout);
      return {
        ...state,
        loggedIn: false,
        loadingAccessToken: false,
      }
    case actions.LOGIN_USER:
      // set after authentication has completed to load dashboard
      return {
        ...state,
        loggedIn: true,
        tokenIsValid: true,
        redirecting: false,
      }
    case actions.VALIDATE_MFA:
      let key = config.encryptKey(sessionStorage.userId);
      sessionStorage.setItem('validMfa', key);
      return {
        ...state,
        validMfa: key,
      }
    case actions.TOKEN_LOADED:
      return {
        ...state,
        loadingAccessToken: false
      }
    case actions.RECEIVE_TOKEN:
      sessionStorage.removeItem('loggingOut');
      localStorage.setItem('accessToken', action.accessToken);
      return {
        ...state,
        loadingAccessToken: false
      }
    case actions.IP_TRUNKING_ONLY_SET:
      return {
        ...state,
        isIpTrunkingOnly: sessionStorage.isIpTrunkingOnly && sessionStorage.isIpTrunkingOnly.toString().toLowerCase() === 'true' ? true : false
      }
    case actions.PERMISSIONS_UPDATED:
      return {
        ...state,
        hasTrunking: sessionStorage.hasTrunk && sessionStorage.hasTrunk.toString().toLowerCase() === 'true' ? true : false,
        hasContactCenter: sessionStorage.hasContactCenter && sessionStorage.hasContactCenter.toString().toLowerCase() === 'true' ? true : false,
        hasMacd: sessionStorage.MacdPerms && JSON.stringify(sessionStorage.MacdPerms).indexOf(1) >= 0 ? true : false,
        hasDevices: sessionStorage.hasDevices && sessionStorage.hasDevices.toString().toLowerCase() === 'true' ? true : false,
        hasIpTrunk: sessionStorage.hasIpTrunk && sessionStorage.hasIpTrunk.toString().toLowerCase() === 'true' ? true : false,
        hasTicketing: sessionStorage.hasTicketing,
        hasVmUsageReport: sessionStorage.hasVmUsageReport && sessionStorage.hasVmUsageReport.toString().toLowerCase() === 'true' ? true : false,
        permissionsRetrieved: true
      };
    case actions.RESTART_SESSION:
      // set after authentication fails and the the retry button is clicked
      return {
        ...state,
        restartSession: true
      }
    case actions.SAVE_LOGIN_ERROR:
      // save error message returned from calling clientApp.loginRedirect()
      return {
        ...state,
        loginError: action.loginError,
        loginErrorDescription: action.loginErrorDescription
      }
    case actions.REFRESH_TOKEN:
      // set if API call receivs a 401 (unauthorized) response so token refresh is attempted
      return {
        ...state,
        requestToken: true
      }
    case actions.UPDATE_TOKEN_STATUS:
      return {
        ...state,
        tokenIsValid: action.tokenIsValid
      }
    case actions.SET_LOCATION:
      // set in devices component in admin dashboard
      return {
        ...state,
        LocationAddress: action.LocationAddress,
        LocationAccountNumber: action.LocationAccountNumber
      }
    case actions.SET_QUICK_VIEW_LOCATION:
      // set in devices component in admin dashboard
      return {
        ...state,
        QuickViewLocationAccountNumber: action.QuickViewLocationAccountNumber
      }
    case actions.SERVICE_SELECTED:
      return {
        ...state,
        serviceLoading: true,
        ServiceNumber: action.ServiceNumber,
        PBXUserID: action.PBXUserID,
        Extension: action.Extension,
        SelectedUserId: action.SelectedUserId
      }
    case actions.SET_SERVICE:
      // set in services & users table when a service number is clicked
      return {
        ...state,
        serviceLoading: false,
        ServiceId: action.ServiceId,
        ServiceNumber: action.ServiceNumber,
        PBXUserID: action.PBXUserID,
        SelectedUserId: action.SelectedUserId,
        AccountNumber: action.AccountNumber,
        DirectoryNumber: action.DirectoryNumber,
        Extension: action.Extension,
        LocationAccountNumber: action.LocationAccountNumber,
        isSelectedServiceIpTrunking: action.isSelectedServiceIpTrunking ? action.isSelectedServiceIpTrunking : false
      }
    case actions.SERVICES_SET:
      return {
        ...state,
        serviceLoading: false
      }
    case actions.SERVICES_SET:
      return {
        ...state,
        serviceLoading: false
      }
    case actions.TOGGLE_SIDEBAR:
      // set in services & users table when a service number is clicked
      return {
        ...state,
        showSideBar: !state.showSideBar,
      }
    case actions.REDIRECT_BETA_ACCESS:
      return {
        ...state,
        beta_access_denied: true
      }
    case actions.REDIRECT_ACCESS:
      return {
        ...state,
        access_denied: true
      }
    case actions.RECEIVE_CUSTOM_IMAGE:
      return {
        ...state,
        customSmallLogo: action.customSmallLogo,
        customLargeLogo: action.customLargeLogo,
      }
    case actions.SET_CALL_LOGS_FILTER:
      return {
        ...state,
        cl_call_type: action.cl_call_type,
        cl_status_type: action.cl_status_type,
        cl_call_category: action.cl_call_category,
        cl_from_date: action.cl_from_date,
        cl_to_date: action.cl_to_date,
        cl_date_type: action.cl_date_type
      }
    case actions.SET_SUBSCRIBER_CALL_LOGS_FILTER:
      return {
        ...state,
        usercl_call_type: action.usercl_call_type,
        usercl_status_type: action.usercl_status_type,
        usercl_call_category: action.usercl_call_category,
        usercl_date_type: action.usercl_date_type,
        usercl_from_date: action.usercl_from_date,
        usercl_to_date: action.usercl_to_date
      }
    case actions.UI_SKINS_APPLIED:
      return {
        ...state,
        themes_loaded: true
      }
    case actions.SET_MSP_LOGIN_DATA:
      return {
        ...state,
        isMspLogin: true,
        mspLoginData: { ...action.data }
      }
  }

  return state;
}

export default reducer;