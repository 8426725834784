import React, { useState, useEffect, Component } from 'react'
import { GoogleMap, InfoWindow, Marker, useLoadScript } from "@react-google-maps/api";
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { Link } from "react-router-dom";
import { DataLoadingSpinner } from '../../../components/spinners';
import * as apiCalls from "../../../requests/api_calls";
import greenPin from "../../../custompins/pin_green.png";
import redPin from "../../../custompins/pin_red.png";
import greyPin from "../../../custompins/pin_grey.png";
import redCluster from "../../../custompins/cluster_red.png";
import yellowCluster from "../../../custompins/cluster_yellow.png";
import greenCluster from "../../../custompins/cluster_green.png";

export default function CircuitMap() {

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY // Add your API key
  });

  const pageContent = () => {
    return (
      <div className="circuit-map-container">
        <div className="container full-card">
          <div className="circuit-map card">
            <div className="card-header">
              <h1>Service Locations</h1>
            </div>
            <Map />
          </div>
        </div >
      </div>
    )
  }

  return pageContent();
}

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMarker: null,
      loading: true,
      markers: [],
      //
      activeFilter: true,
      inactiveFilter: true,
      offlineFilter: true,
      //
      collapseLegend: false,
    };
  }

  componentDidMount() {
    this.fetchCircuits();
  }

  fetchCircuits = async () => {
    let apiPath = "admin/GetCircuitsCoords";
    await apiCalls.fetchData(apiPath).then((data) => {
      if (data) {
        let test = data.filter((itm) => itm.GeoCoordinates !== null);
        let x = test.map((itm, idx) => {
          let lat = itm.GeoCoordinates[0];
          let lng = itm.GeoCoordinates[1];

          // Check if there are duplicates of current lat/lng
          const duplicates = test.filter((item, index) => {
            return index !== idx && item.GeoCoordinates[0] === lat && item.GeoCoordinates[1] === lng;
          });

          // Offset lat/lng for each duplicate
          duplicates.forEach((duplicate) => {
            duplicate.GeoCoordinates[0] += .0001;
            duplicate.GeoCoordinates[1] += .0001;
          });

          return {
            id: idx + 1,
            name: itm.CircuitName ? itm.CircuitName : "",
            position: { lat: lat, lng: lng },
            status: itm.ActiveStatus,
            CheckMkHostName: itm.CheckMkHostName,
            SelectedLocation: itm.LocationName,
            LocationAccountNumber: itm.LocationAccountNumber,
            CircuitName: itm.CircuitName,
            CircuitId: itm.CircuitId,
            CustomerProductId: itm.CustomerProductId,
            NotificationEmail: itm.NotificationEmail,
            SpeedDownstream: itm.SpeedDownstream,
            SpeedUpstream: itm.SpeedUpstream,
            Status: itm.Status,
            NIDIP: itm.NIDIP,
            NIDMAC: itm.NIDMAC,
            ServiceType: itm.ServiceType,
            TermInMonth: itm.TermInMonth,
            DeviceHostName: itm.DeviceHostName,
            WANPort: itm.WANPort,
            WANPortAlias: itm.WANPortAlias,
            AddressLine1: itm.AddressLine1,
            AddressLine2: itm.AddressLine2,
            AddressLine3: itm.AddressLine3,
            AddressId: itm.AddressId,
          };
        });

        let copy = JSON.parse(JSON.stringify(x));
        this.setState({
          markers: x,
          markersCopy: copy,
          loading: false,
        });
      } else {
        this.setState({
          markers: [],
          markersCopy: [],
          loading: false,
        });
      }
    }).catch(err => console.error(err));
  }

  handleActiveMarker = (marker) => {
    if (marker === this.state.activeMarker) {
      return;
    }
    this.setState({
      activeMarker: marker
    });
  };

  handleOnLoad = (map) => {
    this.map = map; // Store the map instance

    const bounds = new google.maps.LatLngBounds();
    this.state.markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);

    // Initialize the MarkerClusterer
    this.updateClusters();
  };


  updateClusters = () => {
    if (this.markerCluster) {
      this.markerCluster.clearMarkers(); // Clear existing clusters
    }

    const renderer = {
      render: (itm) => {
        let hasActiveStatus = (array) => {
          let actives = array.some(item => item.statusType === "active");
          let inactives = array.some(item => item.statusType === "inactive");
          let offlines = array.some(item => item.statusType === "offline");

          if (actives & !inactives && !offlines) {
            return greenCluster;
          } else if (!actives & inactives && !offlines) {
            return redCluster;
          } else {
            return yellowCluster;
          }
        }
        return (
          new google.maps.Marker({
            label: { text: String(itm.count), color: "white", fontSize: "10px" },
            position: itm.position,
            icon: hasActiveStatus(itm.markers),
            zIndex: Number(google.maps.Marker.MAX_ZINDEX) + itm.count,
          })
        )
      },
    };

    // Instantiate MarkerClusterer with the new markers
    this.markerCluster = new MarkerClusterer({
      map: this.map,
      markers: this.createMarkers(),
      renderer,
    });
  };

  createMarkers = () => {
    let { markers, activeMarker } = this.state;

    return markers.map((itm) => {
      const marker = new google.maps.Marker({
        position: itm.position,
        icon: itm.status === "active" ? greenPin : itm.status === "inactive" ? redPin : greyPin,
        statusType: itm.status
      });

      // Add an onClick event listener to the marker
      marker.addListener('click', () => {
        this.handleActiveMarker(itm.id);
      });

      return marker;
    });
  };

  filterActive = async () => {
    let { activeFilter, inactiveFilter, offlineFilter } = this.state;
    let selectedValue = activeFilter ? false : true;
    //
    if (!selectedValue) {
      let filteredArr = this.state.markers.filter(itm => itm.status !== "active");
      await this.setState({
        activeFilter: selectedValue,
        markers: filteredArr,
        activeMarker: null
      });
    } else {
      let test = this.state.markersCopy;
      if (!inactiveFilter) {
        test = test.filter(itm => itm.status !== "inactive");
      }
      if (!offlineFilter) {
        test = test.filter(itm => itm.status !== "Offline");
      }
      //
      await this.setState({
        activeFilter: selectedValue,
        markers: test,
        activeMarker: null
      });
    }
    this.updateClusters();
  }

  filterInactive = async () => {
    let { activeFilter, inactiveFilter, offlineFilter } = this.state;
    let selectedValue = inactiveFilter ? false : true;
    //
    if (!selectedValue) {
      let filteredArr = this.state.markers.filter(itm => itm.status !== "inactive");
      await this.setState({
        inactiveFilter: selectedValue,
        markers: filteredArr,
        activeMarker: null
      });
    } else {
      let test = this.state.markersCopy;
      if (!activeFilter) {
        test = test.filter(itm => itm.status !== "active");
      }
      if (!offlineFilter) {
        test = test.filter(itm => itm.status !== "Offline");
      }
      //
      await this.setState({
        inactiveFilter: selectedValue,
        markers: test,
        activeMarker: null
      });
    }
    this.updateClusters();
  }

  filterOffline = async () => {
    let { activeFilter, inactiveFilter, offlineFilter } = this.state;
    let selectedValue = offlineFilter ? false : true;
    //
    if (!selectedValue) {
      let filteredArr = this.state.markers.filter(itm => itm.status !== "Offline");
      await this.setState({
        offlineFilter: selectedValue,
        markers: filteredArr,
        activeMarker: null
      });
    } else {
      let test = this.state.markersCopy;
      if (!activeFilter) {
        test = test.filter(itm => itm.status !== "active");
      }
      if (!inactiveFilter) {
        test = test.filter(itm => itm.status !== "inactive");
      }
      //
      await this.setState({
        offlineFilter: selectedValue,
        markers: test,
        activeMarker: null
      });
    }
    this.updateClusters();
  }

  customLegend = () => {
    let { activeFilter, inactiveFilter, offlineFilter, collapseLegend } = this.state;
    if (!collapseLegend) {
      return (
        <div
          style={{
            position: "fixed",
            top: "50vh",
            width: "200px",
            backgroundColor: "white",
            borderRadius: "5%",
            border: "3px solid var(--theme-darknav)",
            zIndex: "1",
          }}
          className='legend-container'
        >
          <div
            className='legend-collapse-btn'
            style={{
              cursor: "pointer",
              float: "right",
              marginRight: "10px",
              position: "relative",
              top: "5px"
            }}
            onClick={() => this.setState({ collapseLegend: true })}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-box-arrow-down-left" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M7.364 12.5a.5.5 0 0 0 .5.5H14.5a1.5 1.5 0 0 0 1.5-1.5v-10A1.5 1.5 0 0 0 14.5 0h-10A1.5 1.5 0 0 0 3 1.5v6.636a.5.5 0 1 0 1 0V1.5a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v10a.5.5 0 0 1-.5.5H7.864a.5.5 0 0 0-.5.5z" />
              <path fill-rule="evenodd" d="M0 15.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H1.707l8.147-8.146a.5.5 0 0 0-.708-.708L1 14.293V10.5a.5.5 0 0 0-1 0v5z" />
            </svg>
          </div>
          <div
            className='main-container'
            style={{
              padding: "0 20px 20px 20px"
            }}
          >
            <h5>Statistics</h5>
            <div
              className='count-container'
              style={{
                border: "1px solid grey",
                padding: "10px"
              }}
            >
              <div><b>Total Count: </b>{`${this.state.markersCopy.length}`}</div>
              <div><b>Online: </b>{`${this.state.markersCopy.filter(itm => itm.status === "active").length}`}</div>
              <div><b>Offline: </b>{`${this.state.markersCopy.filter(itm => itm.status === "inactive").length}`}</div>
              <div><b>Unmonitored: </b>{`${this.state.markersCopy.filter(itm => itm.status === "Offline").length}`}</div>
            </div>
            <div
              className='check-container'
              style={{
                border: "1px solid grey",
                padding: "10px"
              }}
            >
              {this.customCheckbox(activeFilter, "Online", this.filterActive, "green")}
              {this.customCheckbox(inactiveFilter, "Offline", this.filterInactive, "red")}
              {this.customCheckbox(offlineFilter, "Unmonitored", this.filterOffline, "grey")}
            </div>
          </div>
        </div>
      )
    } else {
      return <button
        className='legend-expand-btn'
        style={{
          position: "fixed",
          top: "50vh",
          backgroundColor: "transparent",
          borderColor: "transparent",
          zIndex: "1",
        }}
        onClick={() => this.setState({ collapseLegend: false })}
      >
        <div
          className="arrow-right"
          style={{
            width: "0",
            height: "0",
            borderTop: "60px solid transparent",
            borderBottom: "60px solid transparent",
            borderLeft: "20px solid var(--theme-darknav)",
          }}
        ></div>
      </button >
    }
  }

  customCheckbox = (filterVal, label, handleFilter, color) => {
    const checkMark = () => {
      if (filterVal) {
        return (
          <div
            className="checkmark"
            style={{
              color: "white"
            }}
          >
            ✓
          </div>
        );
      } else {
        // hide check mark wihtout compromising element positioning
        return (
          <div
            className="checkmark"
            style={{
              color: "white",
              /* Hide the text. */
              textIndent: "100%",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }
            }
          >
            ✓
          </div >
        );
      }
    };
    return (
      <div className="form-check">
        <div
          onClick={handleFilter}
          className="checkbox"
          style={{
            cursor: "pointer",
            height: "15px",
            width: "15px",
            backgroundColor: color,
            textAlign: "center",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "15%",
            marginRight: "5px"
          }}
        >
          {checkMark()}
        </div>
        <span>{label}</span>
      </div >
    );
  }

  render() {
    let { loading, markers, markersCopy, activeMarker } = this.state;
    if (!loading) {
      if (markersCopy && markersCopy.length > 0) {
        return (
          <div>
            {this.customLegend()}
            <GoogleMap
              onLoad={this.handleOnLoad}
              onClick={() => this.setState({ activeMarker: null })}
              mapContainerStyle={{ width: "100%", height: "75vh" }}
            >
              {markers.map((itm) => {
                return (
                  <Marker
                    key={itm.id}
                    position={itm.position}
                    onClick={() => this.handleActiveMarker(itm.id)}
                    icon={null}
                    // set as NOT visible to avoid double rendering from createMarkers();
                    // this way we can still have the InfoWindow and all the functionality/state management maintained
                    visible={false}
                  >
                    {activeMarker === itm.id ? (
                      <InfoWindow onCloseClick={() => this.setState({ activeMarker: null })}>
                        <div>
                          <p>{itm.AddressLine1}</p>
                          <p>{itm.AddressLine2}</p>
                          <p>{itm.AddressLine3}</p>
                          <p>Circuit ID: {itm.name}</p>
                          <p>Speed: {itm.SpeedDownstream}</p>
                          <p>
                            <Link to={{
                              pathname: '/new_ticket',
                              state: {
                                redirectedFromCircuitsList: [
                                  {
                                    redirectedFromCircuits: true,
                                    CheckMkHostName: itm.CheckMkHostName,
                                    SelectedLocation: itm.SelectedLocation,
                                    LocationAccountNumber: itm.LocationAccountNumber,
                                    CircuitName: itm.CircuitName,
                                    CircuitId: itm.CircuitId,
                                    CustomerProductId: itm.CustomerProductId,
                                    NotificationEmail: itm.NotificationEmail,
                                    SpeedDownstream: itm.SpeedDownstream,
                                    SpeedUpstream: itm.SpeedUpstream,
                                    Status: itm.Status,
                                    NIDIP: itm.NIDIP,
                                    NIDMAC: itm.NIDMAC,
                                    ServiceType: itm.ServiceType,
                                    TermInMonth: itm.TermInMonth
                                  }
                                ],

                              }
                            }}>
                              [Open Ticket]
                            </Link>
                          </p>
                          {itm.CircuitId && itm.CheckMkHostName && itm.CheckMkHostName !== "" ? <p>
                            <Link
                              to={{
                                pathname: `/circuits-analytics/${itm.CircuitId}`,
                                state: {
                                  CircuitId: itm.CircuitId,
                                  HostName: itm.CheckMkHostName,
                                  CircuitName: itm.CircuitName,
                                  DeviceHostName: itm.DeviceHostName,
                                  WANPortAlias: itm.WANPortAlias,
                                  WANPort: itm.WANPort,
                                }
                              }}
                              id="editButton"
                            >
                              [View Graphs]
                            </Link>
                          </p> : null}
                        </div>
                      </InfoWindow>
                    ) : null}
                  </Marker>
                )
              })}
            </GoogleMap>
          </div>
        );
      } else {
        return <p style={{ textAlign: 'center', paddingTop: '15px' }}>No Available Data</p>
      }
    } else {
      return (
        <div style={{ position: "relative", textAlign: "center" }}><DataLoadingSpinner className='load spinner' style={{ marginTop: "3%" }} /></div>
      )
    }
  }
}