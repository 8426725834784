import React, { useState, useEffect } from "react";
import * as apiCalls from "../../requests/api_calls";
import { Modal } from "react-bootstrap";
import {
  Grid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import "./circuits.css";
import { Button } from "@progress/kendo-react-buttons";
import { Link } from "react-router-dom";
import { orderBy } from '@progress/kendo-data-query';
import { filterBy } from '@progress/kendo-data-query';
import { DataLoadingSpinner } from '../../components/spinners';

function CircuitList() {
  const [showModal, setShowModal] = useState(false);
  const [circuitList, setCircuitList] = useState([
    {
      LocationAccountNumber: "",
      LocationName: "",
      CircuitId: "",
      CircuitName: "",
      NIDMAC: "",
      NotificationEmail: "",
      CheckMkHostName: "",
      Status: "",
      CustomerProductId: "",
      SpeedDownstream: "",
      SpeedUpstream: "",
      TermInMonth: "",
    },
  ]);
  const [filteredCircuitList, setFilteredCircuitList] = useState([
    {
      LocationAccountNumber: "",
      LocationName: "",
      CircuitId: "",
      CircuitName: "",
      NIDMAC: "",
      NotificationEmail: "",
      CheckMkHostName: "",
      Status: "",
      CustomerProductId: "",
      SpeedDownstream: "",
      SpeedUpstream: "",
      TermInMonth: "",
    },
  ]);
  const [fullListings, setFullListings] = useState([
    {
      LocationAccountNumber: "",
      LocationName: "",
      CircuitId: "",
      CircuitName: "",
      NIDMAC: "",
      NotificationEmail: "",
      CheckMkHostName: "",
      Status: "",
      CustomerProductId: "",
      SpeedDownstream: "",
      SpeedUpstream: "",
      TermInMonth: "",
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [Sort, setSort] = useState([{ field: "ActiveStatus", dir: "asc" }],)
  const [editCircuitItem, setEditCircuitItem] = useState({
    CircuitId: "",
    CircuitName: "",
    NotificationEmail: "",
    CustomerProductId: "",
    SpeedDownstream: "",
    SpeedUpstream: "",
    TermInMonth: "",
    CheckMkHostName: "",
    GoogleLat: "",
    GoogleLong: "",
    OverrideLat: "",
    OverrideLong: "",
    DisplayOverrideLat: "",
    DisplayOverrideLong: "",
    AddressId: "",
    GeoCodeReset: "",
  });

  useEffect(() => {
    setLoading(true);
    //fetch circuit data
    fetchCircuits();
  }, []);

  const fetchCircuits = async () => {
    let apiPath = "admin/GetCircuitsList";

    await apiCalls.fetchData(apiPath).then((data) => {
      setLoading(true);
      if (data) {
        setCircuitList(data);
        setFilteredCircuitList(data);
        setFullListings(JSON.parse(JSON.stringify(data)));
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  };

  const handleSortChange = (e) => {
    setFilteredCircuitList(orderBy(filteredCircuitList, Sort));
    setSort(e.sort)
  }

  const searchCircuits = (event) => {
    let searchText = event.target.value.trim();
    let fullDetails = fullListings;
    if (searchText !== '') {
      searchText = event.target.value;
      let services = filterBy(fullDetails, {
        logic: 'or',
        filters: [
          { field: 'CircuitId', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'CircuitName', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Address1', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'City', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'State', operator: 'contains', value: searchText, ignoreCase: true },
          { field: 'Zip', operator: 'contains', value: searchText, ignoreCase: true },
        ]
      });
      setFilteredCircuitList(services);
    }
    else {
      setFilteredCircuitList(fullDetails);
    }
  }

  const SpeedCell = (props) => {
    let { SpeedDownstream } = props.dataItem;
    if (SpeedDownstream) {
      return (
        <td>
          {SpeedDownstream}
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  }

  const LocationCell = (props) => {
    let { Address1, City, State, Zip } = props.dataItem;
    if (Address1) {
      return (
        <td>
          {`${Address1}, ${City}, ${State} ${Zip}`}
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  }

  const StatusCell = (props) => {
    let customClassName = "";
    if (props.dataItem.ActiveStatus === "active") {
      customClassName = "dot dot-success";
    }
    if (props.dataItem.ActiveStatus === "inactive") {
      customClassName = "dot dot-error";
    }
    if (props.dataItem.CheckMkHostName && props.dataItem.CheckMkHostName !== "") {
      return (
        <td>
          <i className={customClassName} />
        </td>
      )
    } else {
      return (
        <td></td>
      )
    }
  };

  const DataLinkCell = (props) => {
    let { CircuitId, CheckMkHostName, CircuitName, DeviceHostName, WANPortAlias, WANPort } = props.dataItem;
    if (CircuitId) {
      if (CheckMkHostName && CheckMkHostName !== "") {
        //console.log("PROPS", props);
        return (
          <td>
            <Link
              to={{
                pathname: `/circuits-analytics/${CircuitId}`,
                state: {
                  CircuitId: CircuitId,
                  HostName: CheckMkHostName,
                  CircuitName: CircuitName,
                  DeviceHostName: DeviceHostName,
                  WANPortAlias: WANPortAlias,
                  WANPort: WANPort,
                }
              }}
              id="editButton"
              className="icon btn"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-graph-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M0 0h1v15h15v1H0V0Zm14.817 3.113a.5.5 0 0 1 .07.704l-4.5 5.5a.5.5 0 0 1-.74.037L7.06 6.767l-3.656 5.027a.5.5 0 0 1-.808-.588l4-5.5a.5.5 0 0 1 .758-.06l2.609 2.61 4.15-5.073a.5.5 0 0 1 .704-.07Z" />
              </svg>
            </Link>
          </td>
        );
      } else {
        return (
          <td>

          </td>
        );
      }
    } else {
      return <td></td>
    }
  };

  const onHandleShow = (item) => {
    setShowModal(true);
    setEditCircuitItem({
      ...editCircuitItem,
      CircuitId: item.CircuitId,
      NIDMAC: item.NIDMAC,
      NotificationEmail: item.NotificationEmail,
      CircuitName: item.CircuitName,
      LocationName: item.LocationName,
      CustomerProductId: item.CustomerProductId,
      SpeedDownstream: item.SpeedDownstream,
      SpeedUpstream: item.SpeedUpstream,
      TermInMonth: item.TermInMonth,
      CheckMkHostName: item.CheckMkHostName,
      GoogleLat: item.GoogleLat,
      GoogleLong: item.GoogleLong,
      OverrideLat: item.OverrideLat,
      OverrideLong: item.OverrideLong,
      DisplayOverrideLat: item.OverrideLat,
      DisplayOverrideLong: item.OverrideLong,
      AddressId: item.AddressId,
      GeoCodeReset: item.GeoCodeReset
    });
  };
  const onHandleClose = () => {
    setShowModal(false);
  };

  const EditCell = (props) => {
    return (
      <>
        {props.dataItem.NotificationEmail != "" && <td>
          <span>
            <a
              id="editButton"
              className="icon btn"
              onClick={() => onHandleShow(props.dataItem)}
            >
              <svg width="14" height="14" viewBox="0 0 24 24">
                <path
                  d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                  transform="scale(0.046875 0.046875)"
                ></path>
              </svg>
            </a>
          </span>
        </td>} </>
    );
  };

  const openANewTicket = (props) => {
    return (
      <>
        {props.dataItem.NotificationEmail != "" && <td>
          <span>
            <Link to={{
              pathname: '/new_ticket',
              state: {
                redirectedFromCircuitsList: [
                  {
                    redirectedFromCircuits: true,
                    CheckMkHostName: props.dataItem.CheckMkHostName,
                    SelectedLocation: props.dataItem.LocationName,
                    LocationAccountNumber: props.dataItem.LocationAccountNumber,
                    CircuitName: props.dataItem.CircuitName,
                    CircuitId: props.dataItem.CircuitId,
                    CustomerProductId: props.dataItem.CustomerProductId,
                    NotificationEmail: props.dataItem.NotificationEmail,
                    SpeedDownstream: props.dataItem.SpeedDownstream,
                    SpeedUpstream: props.dataItem.SpeedUpstream,
                    Status: props.dataItem.Status,
                    NIDIP: props.dataItem.NIDIP,
                    NIDMAC: props.dataItem.NIDMAC,
                    ServiceType: props.dataItem.ServiceType,
                    TermInMonth: props.dataItem.TermInMonth
                  }
                ],

              }
            }}>
              Open Ticket
            </Link>
          </span>
        </td>} </>
    );
  };

  const editCircuitName = (e) => {
    setEditCircuitItem({ ...editCircuitItem, CircuitName: e.target.value });
  };
  const editDownloadSpeed = (e) => {
    setEditCircuitItem({ ...editCircuitItem, SpeedDownstream: e.target.value });
  };
  const editUploadSpeed = (e) => {
    setEditCircuitItem({ ...editCircuitItem, SpeedUpstream: e.target.value });
  };
  const editCircuitNotification = (e) => {
    setEditCircuitItem({ ...editCircuitItem, NotificationEmail: e.target.value });
  };
  const editOverrideLat = (e) => {
    setEditCircuitItem({ ...editCircuitItem, OverrideLat: e.target.value });
  };
  const editOverrideLong = (e) => {
    setEditCircuitItem({ ...editCircuitItem, OverrideLong: e.target.value });
  };
  const updateCircuitList = async () => {
    var apiPath = "admin/UpdateCircuitsList";

    let circuitsDTO = {
      CustomerProductId: editCircuitItem.CustomerProductId,
      CircuitId: editCircuitItem.CircuitId,
      CircuitName: editCircuitItem.CircuitName,
      NotificationEmail: editCircuitItem.NotificationEmail,
      NIDMAC: editCircuitItem.NIDMAC,
      LocationName: editCircuitItem.LocationName,
      NIDIP: editCircuitItem.NIDIP,
      SpeedDownstream: editCircuitItem.SpeedDownstream,
      SpeedUpstream: editCircuitItem.SpeedUpstream,
      TermInMonth: editCircuitItem.TermInMonth,
      CheckMkHostName: editCircuitItem.CheckMkHostName,
      OverrideLat: editCircuitItem.OverrideLat,
      OverrideLong: editCircuitItem.OverrideLong,
      AddressId: editCircuitItem.AddressId,
    };
    // console.log("DATA", circuitsDTO);
    let response = await apiCalls.post(apiPath, 'POST', JSON.stringify(circuitsDTO)).then(res => res).catch(err => console.error(err));
    if (response.ok) {
      fetchCircuits();
      setShowModal(false);
    } else {
      setLoading(false);
    }
  };

  const removeOverrideCoords = async (p_AddressId) => {
    await setLoading(true);
    let path = `admin/ResetCircuitsCoords`;
    let response = await apiCalls.post(path, 'POST', JSON.stringify(p_AddressId)).then(response => response).catch(err => console.error(err));
    if (response.ok) {
      fetchCircuits();
      setShowModal(false);
    } else {
      setLoading(false);
    }
  }

  const gridContent = () => {
    if (loading) {
      return <div style={{ position: "relative", textAlign: "center" }}><DataLoadingSpinner className='load spinner' style={{ marginTop: "3%" }} /></div>
    } else {
      return (
        <div className="circuit-card-holder">
          <div className="favorite-services card">
            <div className="card-header">
              <h2 className="move">Circuits List</h2>
              <div className="search">
                <label htmlFor="fav_services_srch_tb" className="hidden">
                  circuits search
                </label>
                <input
                  id="fav_services_srch_tb"
                  type="text"
                  autoComplete={"off"}
                  className="form-control search-control"
                  onChange={searchCircuits}
                />
                <svg width="12" height="12" viewBox="0 0 23 23">
                  <path
                    d="m347 238c0-36-12-66-37-91-25-25-55-37-91-37-35 0-65 12-90 37-25 25-38 55-38 91 0 35 13 65 38 90 25 25 55 38 90 38 36 0 66-13 91-38 25-25 37-55 37-90z m147 237c0 10-4 19-11 26-7 7-16 11-26 11-10 0-19-4-26-11l-98-98c-34 24-72 36-114 36-27 0-53-5-78-16-25-11-46-25-64-43-18-18-32-39-43-64-10-25-16-51-16-78 0-28 6-54 16-78 11-25 25-47 43-65 18-18 39-32 64-43 25-10 51-15 78-15 28 0 54 5 79 15 24 11 46 25 64 43 18 18 32 40 43 65 10 24 16 50 16 78 0 42-12 80-36 114l98 98c7 7 11 15 11 25z"
                    transform="scale(0.046875 0.046875)"
                  ></path>
                </svg>
              </div>

              <div className="card-content">
                <Grid
                  scrollable="none"
                  style={{ height: "auto" }}
                  data={orderBy(filteredCircuitList, Sort)}
                  sortable={{ allowUnsort: true, mode: 'single' }}
                  sort={Sort}
                  onSortChange={handleSortChange}
                >
                  <Column
                    field="CircuitId"
                    title="Circuit ID"
                  />
                  <Column
                    field="CircuitName"
                    title="Circuit Name"
                  />
                  <Column field="SpeedDownstream" title="Speed (Mbps)" cell={SpeedCell} />
                  <Column field="LocationName" title="Location" cell={LocationCell} />
                  <Column
                    field="ActiveStatus"
                    title="Status"
                    cell={StatusCell}
                  />
                  {/* Hiding OpenTicket if hasTicketing is None */}
                  {sessionStorage.hasTicketing === 'NONE' ? null :
                    <Column
                      field="OpenANewTicket"
                      title=" "
                      width="30px"
                      cell={openANewTicket}
                      onRowClick
                    />}
                  <Column
                    field="graph"
                    title=" "
                    width="30px"
                    cell={DataLinkCell}
                  />
                  <Column
                    field="FavoriteBtn"
                    title=" "
                    width="30px"
                    cell={EditCell}
                    onRowClick
                  />
                </Grid>
              </div>
            </div>
          </div>

          <div>
            <Modal show={showModal} onHide={onHandleClose}>
              <Modal.Header closeButton></Modal.Header>
              <h4 className="modalCircuitsTitle">Edit Circuit Item</h4>
              <Modal.Body>
                <div className="fqdn-setup-modal">
                  {formInput("new-circuitId", "Circuit ID", true, editCircuitItem.CircuitId)}
                  {formInput("new-circuitName", "Circuit Name", false, editCircuitItem.CircuitName, editCircuitName)}
                  {formInput("new-NIDMAC", "NID MAC", true, editCircuitItem.NIDMAC)}
                  {formInput("new-circuitDownloadSpeed", "Download Speed (Mbps)", false, editCircuitItem.SpeedDownstream, editDownloadSpeed)}
                  {formInput("new-circuitUploadSpeed", "Upload Speed (Mbps)", false, editCircuitItem.SpeedUpstream, editUploadSpeed)}
                  {formInput("new-location", "Location", true, editCircuitItem.LocationName)}
                  {formInput("new-interface", "Interface", true, editCircuitItem.CheckMkHostName)}
                  {formInput("new-email", "Notification Email", false, editCircuitItem.NotificationEmail, editCircuitNotification)}
                  {formInput(
                    "new-overrideLat",
                    `Set Latitude (current: ${editCircuitItem.GeoCodeReset == false && editCircuitItem.DisplayOverrideLat !== null ? editCircuitItem.OverrideLat : editCircuitItem.GoogleLat})`,
                    false,
                    editCircuitItem.GeoCodeReset == false && editCircuitItem.OverrideLat !== null ? editCircuitItem.OverrideLat : null,
                    editOverrideLat
                  )}
                  {formInput(
                    "new-overrideLong",
                    `Set Longitude (current: ${editCircuitItem.GeoCodeReset == false && editCircuitItem.DisplayOverrideLong !== null? editCircuitItem.OverrideLong : editCircuitItem.GoogleLong})`,
                    false,
                    editCircuitItem.GeoCodeReset == false && editCircuitItem.OverrideLong !== null ? editCircuitItem.OverrideLong : null,
                    editOverrideLong
                  )}
                  <a onClick={() => removeOverrideCoords(editCircuitItem.AddressId)} className="no-btn">Reset Override Coordinates</a>
                </div>
              </Modal.Body>
              <hr />
              <div className="btns">
                <Button className="btn" onClick={updateCircuitList}>
                  Save
                </Button>
                <Button className="btn" onClick={() => onHandleClose()}>
                  Cancel
                </Button>
              </div>
            </Modal>
          </div>
        </div>
      )
    }
  }

  const formInput = (id, label, isDisabled, value, onChange = null) => {
    return (
      <div className="form-group">
        <label>{label}</label>
        <input
          id={id}
          type="text"
          disabled={isDisabled}
          className="form-control"
          value={value}
          onChange={onChange}
        />
      </div>
    )
  }

  return (
    <>
      {gridContent()}
    </>
  );
}

export default CircuitList;