import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@progress/kendo-buttons-react-wrapper";

import { fetchData, post } from "../../../../requests/api_calls";

import { DataLoadingSpinner } from "../../../../components/spinners";

import { BUSINESS_HOURS, HOLIDAY_SCHEDULE } from "./constants";
import { ScheduleItem } from "./schedule-item.jsx";
import { ScheduleList } from "./schedule-list.jsx";
import { AddScheduleItem } from "./add-schedule-item.jsx";

class ScheduleModal extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      type: "",
      showModal: false,
      loading: false,
      allSchedulesData: null,
      timeSchedules: null,
      selectedSchedulesData: null,
      itemsToDelete: [],
      selectedSchedule: null,
      showScheduleItemContent: false,
      scheduleItemsToDelete: [],
    };
  }

  handleClose() {
    this.setState({
      type: "",
      showModal: false,
      loading: false,
      allSchedulesData: null,
      timeSchedules: null,
      itemsToDelete: [],
      selectedSchedule: null,
      showScheduleItemContent: false,
      scheduleItemsToDelete: [],
    });
  }

  async handleShow() {
    this.setState({ showModal: true, loading: true });
    await this.fetchTimeSchedules();
    this.setState({ loading: false });
  }

  fetchTimeSchedules = async () => {
    const allSchedulesData = await fetchData("admin/enterprise/GetTimeSchedules").then(
      (allSchedulesData) => allSchedulesData
    );
    this.setState({
      allSchedulesData: allSchedulesData,
    });
  };

  handleSelectScheduleType = (type) => {
    const timeSchedules = !type
      ? null
      : type === HOLIDAY_SCHEDULE
      ? this.state.allSchedulesData?.HolidaySchedules
      : this.state.allSchedulesData?.TimeSchedules;
    this.setState({ type: type, timeSchedules: timeSchedules });
  };

  addSchedule = async (newScheduleName) => {
    const scheduleType = this.state.type === HOLIDAY_SCHEDULE ? 0 : 1;
    const data = {
      ScheduleName: newScheduleName,
      ScheduleType: scheduleType,
    };
    const path = "admin/enterprise/AddTimeSchedule";
    try {
      this.setState({ loading: true });
      const response = await post(path, "POST", JSON.stringify(data));
      if (response.ok) {
        await this.fetchTimeSchedules();
        this.handleSelectScheduleType(this.state.type);
        this.setState({ loading: false });
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  };

  onShowScheduleItem = async (item) => {
    this.setState({
      selectedSchedule: item || null,
      showScheduleItemContent: Boolean(item),
    });
  };

  selectScheduleItemsToDelete = async (scheduleListToDelete) => {
    this.setState({ scheduleItemsToDelete: scheduleListToDelete });
  };

  selectItemToDelete = (isItemSelected, deleteEventData) => {
    const isItemInItemsToDeleteArray = this.state.itemsToDelete.some(
      (item) => item.EventName === deleteEventData.EventName
    );
    if (isItemSelected && !isItemInItemsToDeleteArray) {
      const itemListToDelete = [...this.state.itemsToDelete, deleteEventData];
      this.setState({ itemsToDelete: itemListToDelete });
    }
    if (!isItemSelected && isItemInItemsToDeleteArray) {
      const itemListToDelete = this.state.itemsToDelete.filter(
        (item) => item.EventName !== deleteEventData.EventName
      );

      this.setState({ itemsToDelete: itemListToDelete });
    }
  };

  updateScheduleName = (newScheduleName) => {
    this.setState({
      selectedSchedule: { ...this.state.selectedSchedule, NewScheduleName: newScheduleName },
    });
  };

  updateSchedule = async () => {
    const path = "admin/enterprise/UpdateTimeSchedule";
    const { selectedSchedule } = this.state;

    const data = {
      ...selectedSchedule,
    };
    console.log({ data });
    try {
      this.setState({ loading: true });
      const response = await post(path, "POST", JSON.stringify(data));
      if (response.ok) {
        await this.fetchTimeSchedules();
        this.handleSelectScheduleType(this.state.type);
        this.setState({
          loading: false,
          itemsToDelete: [],
          showScheduleItemContent: false,
        });
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  };

  deleteSchedules = async () => {
    const path = "admin/enterprise/DeleteTimeSchedules";
    const { scheduleItemsToDelete, type } = this.state;
    try {
      this.setState({ loading: true });
      const response = await post(path, "DELETE", JSON.stringify(scheduleItemsToDelete));
      if (response.ok) {
        await this.fetchTimeSchedules();
        this.handleSelectScheduleType(type);
        this.setState({
          loading: false,
          type: type,
          scheduleItemsToDelete: [],
        });
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  };

  deleteListedEvents = async () => {
    const path = "admin/enterprise/DeleteTimeScheduleEvents";
    const { selectedSchedule, itemsToDelete } = this.state;
    try {
      this.setState({ loading: true });
      const response = await post(path, "DELETE", JSON.stringify(itemsToDelete));
      if (response.ok) {
        const newEventList =
          selectedSchedule?.events.length > 0
            ? selectedSchedule?.events.filter(
                (event) =>
                  !itemsToDelete.some(
                    (eventToDelete) => eventToDelete.EventName === event.EventName
                  )
              )
            : [];
        this.setState({
          loading: false,
          itemsToDelete: [],
          selectedSchedule: {
            ...selectedSchedule,
            events: newEventList,
          },
        });
      } else {
        console.error(`${path}: failed: ${response.message}`);
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      this.setState({ loading: false });
    }
  };

  componentDidMount() {}

  getInfo() {
    switch (this.state.type) {
      case "BUSINESS_HOURS":
        return "Set Business Hours Schedules to automatically trigger feature functionality and time-of-day routing for services.";
      case "HOLIDAY_SCHEDULE":
        return "Set Holiday Hours Schedules to automatically trigger feature functionality and day(s)-of-year routing for services.";
      default:
        return "";
    }
  }

  getTitle() {
    switch (this.state.type) {
      case "BUSINESS_HOURS":
        return "Time Schedule - Business Hours";
      case "HOLIDAY_SCHEDULE":
        return "Time Schedule - Holiday";
      default:
        return "Time Schedules";
    }
  }

  render() {
    let modalContent;
    const {
      type,
      showModal,
      timeSchedules,
      loading,
      itemsToDelete,
      showScheduleItemContent,
      selectedSchedule,
      scheduleItemsToDelete,
    } = this.state;

    if (loading) {
      modalContent = (
        <div className="content">
          <DataLoadingSpinner
            className="spinner"
            style={{ margin: "10px auto 0", height: "40px" }}
          />
        </div>
      );
    } else if (!type) {
      modalContent = (
        <div className="settings-row">
          <div className="setting">
            <span onClick={() => this.handleSelectScheduleType(HOLIDAY_SCHEDULE)}>
              <span className="name">{"HOLIDAY SCHEDULE"}</span>
              <a className="icon btn">
                <svg width="14" height="14" viewBox="0 0 24 24">
                  <path
                    d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                    transform="scale(0.046875 0.046875)"
                  ></path>
                </svg>
              </a>
            </span>
          </div>
          <div className="setting">
            <span onClick={() => this.handleSelectScheduleType(BUSINESS_HOURS)}>
              <span className="name">{"BUSINESS HOURS"}</span>
              <a className="icon btn">
                <svg width="14" height="14" viewBox="0 0 24 24">
                  <path
                    d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                    transform="scale(0.046875 0.046875)"
                  ></path>
                </svg>
              </a>
            </span>
          </div>
        </div>
      );
    } else if (showScheduleItemContent) {
      modalContent = (
        <ScheduleItem
          scheduleItem={selectedSchedule}
          selectItemToDelete={this.selectItemToDelete}
          onUpdateScheduleName={this.updateScheduleName}
        />
      );
    } else if (type && timeSchedules && Object.keys(timeSchedules).length > 0) {
      modalContent = (
        <>
          <p>{this.getInfo()}</p>
          <div className="schedule-block">
            <div>
              <button
                className="no-btn"
                style={{ marginBottom: "10px" }}
                onClick={() => {
                  this.setState({ type: "" });
                }}
              >
                Return to previous view
              </button>
            </div>
            <AddScheduleItem addSchedule={this.addSchedule} />

            <ScheduleList
              schedulesList={timeSchedules}
              onShowScheduleItem={this.onShowScheduleItem}
              onSetListScheduleItemToDelete={this.selectScheduleItemsToDelete}
            />
          </div>
        </>
      );
    } else {
      modalContent = <></>;
    }

    return (
      <div className="setting">
        <span onClick={this.handleShow}>
          <span className="name">Time Schedules</span>
          <a className="icon btn">
            <svg width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </span>
        <Modal show={showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              {showScheduleItemContent && selectedSchedule !== null
                ? `Edit - ${selectedSchedule.ScheduleName}`
                : this.getTitle()}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
          <Modal.Footer>
            {showScheduleItemContent && selectedSchedule !== null && (
              <>
                <hr />
                <div className="btns">
                  <Button className="btn" click={this.updateSchedule}>
                    Save
                  </Button>
                  {itemsToDelete.length > 0 && (
                    <Button className="btn" click={this.deleteListedEvents}>
                      Delete Events
                    </Button>
                  )}
                  <Button className="btn" click={() => this.onShowScheduleItem(null)}>
                    Return
                  </Button>
                  <Button className="btn" click={this.handleClose}>
                    Cancel
                  </Button>
                </div>
              </>
            )}
            {type && timeSchedules && Object.keys(timeSchedules).length > 0 && (
              <>
                {scheduleItemsToDelete.length > 0 && (
                  <Button className="btn" click={this.deleteSchedules}>
                    Delete
                  </Button>
                )}
              </>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default ScheduleModal;
