import React, { useState } from "react";
import { DepartmentCollapse } from "./department-collapse";
import { post } from "../../../../requests/api_calls";

export const AddDepartment = ({ onSuccessAddOrEdit }) => {
  const [showAddDepartmentSection, setShowAddDepartmentSection] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const saveDepartment = async (name, parent) => {
    const path = "admin/enterprise/AddDepartment";
    setErrorMessage("");
    try {
      const data = {
        DepartmentName: name,
        ParentDepartment: parent ? { DepartmentName: parent } : null,
      };
      const response = await post(path, "POST", JSON.stringify(data));
      if (response.ok) {
        onSuccessAddOrEdit();
      } else {
        console.error(`${path}: failed: ${response.message}`);
        setErrorMessage("Failed adding new department.");
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
      setErrorMessage("Failed adding new department.");
    }
  };

  return (
    <>
      <tr id="AddButton">
        <td colSpan="2">
          <div className="btns">
            <a
              className="btn"
              onClick={() => setShowAddDepartmentSection(!showAddDepartmentSection)}
            >
              Add Department
            </a>
          </div>
        </td>
      </tr>
      {showAddDepartmentSection && (
        <tr className="" id="dmAdd">
          <DepartmentCollapse
            type={"ADD"}
            onSaveDepartment={saveDepartment}
            onClose={() => {
              setShowAddDepartmentSection(false);
              setErrorMessage("");
            }}
            errorMessage={errorMessage}
          />
        </tr>
      )}
    </>
  );
};
