import React, { useState } from "react";
import { DepartmentCollapse } from "./department-collapse";
import { post } from "../../../../requests/api_calls";

export const DepartmentItem = ({ department, index, onSuccessAddOrEdit }) => {
  const [showCollapse, setShowCollapse] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const deleteDepartment = async (name) => {
    const path = `admin/enterprise/DeleteDepartment?departmentName=${name}`;
    setErrorMessage("");
    try {
      const response = await post(path, "DELETE", {});
      if (response.ok) {
        await onSuccessAddOrEdit();
      } else {
        const errorMsg = getErrorMessage(response.message);
        console.error(`${path}: failed: ${response.message}`);
        setErrorMessage(errorMsg);
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
    }
  };

  const getErrorMessage = (errorResponse) => {
    const pattern = /\[Error \d+\]\s([^\.]+?\.\s[^\.]+?\.)\s*(?=UserId|Name|Username|$)/;
    const match = errorResponse.match(pattern);
    const errorMessage = match ? match[1].trim() : errorResponse;

    return errorMessage;
  };

  const saveEditDepartment = async (name, parent, newName, type) => {
    const path = "admin/enterprise/UpdateDepartment";
    try {
      const data = {
        DepartmentName: name,
        NewDepartmentName: newName || name,
        ParentDepartment: parent ? { DepartmentName: parent } : null,
      };
      const response = await post(path, "POST", JSON.stringify(data));
      if (response.ok) {
        await onSuccessAddOrEdit();
      } else {
        const errorMsg = getErrorMessage(response.message);
        console.error(`${path}: failed: ${response.message}`);
        setErrorMessage(errorMsg);
      }
    } catch (error) {
      console.error(`${path}: failed: ${error}`);
    }
  };

  return (
    <>
      <tr key={`${index}-item`} id={`item-${index}`}>
        <td>{department.FullPathName}</td>
        <td>
          <a
            className="icon btn"
            title="Edit department"
            onClick={() => setShowCollapse(!showCollapse)}
          >
            <svg alt="Edit" width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </td>
      </tr>
      {showCollapse && (
        <tr className="" id={`dm${index}`} key={`${index}-collapse`}>
          <DepartmentCollapse
            department={department}
            onSaveDepartment={saveEditDepartment}
            onDeleteDepartment={deleteDepartment}
            onClose={() => {
              setShowCollapse(false);
              setErrorMessage("");
            }}
            errorMessage={errorMessage}
          />
        </tr>
      )}
    </>
  );
};
