import React, { useEffect, useState } from "react";

import { DataLoadingSpinner } from "../../../../components/spinners";
import { fetchData } from "../../../../requests/api_calls";

export const DepartmentCollapse = ({
  department,
  type = "EDIT",
  onSaveDepartment,
  onClose,
  onDeleteDepartment,
  errorMessage,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(department?.DepartmentName || "");
  const [parent, setParent] = useState(department?.ParentDepartment?.DepartmentName || null);
  const [newName, setNewName] = useState("");
  const [availableParentDepartments, setAvailableParentDepartments] = useState([]);

  const isAddDepartmentType = type === "ADD";

  useEffect(() => {
    setLoading(true);
    const path = isAddDepartmentType
      ? "admin/enterprise/GetAvailableParentDepartments?departmentName="
      : `admin/enterprise/GetAvailableParentDepartments?departmentName=${department?.DepartmentName}`;
    const fetchAvailableDepartments = async () => {
      const data = await fetchData(path)
        .then((departments) => departments)
        .then((data) => data);
      setAvailableParentDepartments(data);
      setLoading(false);
    };

    fetchAvailableDepartments().catch(console.error);
  }, []);

  const saveDepartment = async () => {
    setLoading(true);
    await onSaveDepartment(name, parent, newName, type);
  };

  const deleteDepartment = async () => {
    setLoading(true);
    await onDeleteDepartment(name);
  };

  if (loading && !errorMessage) {
    return (
      <td colSpan="2">
        <div className="content">
          <DataLoadingSpinner
            className="spinner"
            style={{ margin: "10px auto 0", height: "40px" }}
          />
        </div>
      </td>
    );
  }

  if (!department && !isAddDepartmentType) return <></>;

  if (department && Object.keys(department).length <= 0 && !isAddDepartmentType) {
    return <></>;
  }

  return (
    <td colSpan="2">
      <div className="table-edit">
        {!isAddDepartmentType ? (
          <div className="form-group">
            <a
              className="pull-right red"
              style={{ cursor: "pointer" }}
              onClick={() => deleteDepartment()}
            >
              Delete Department
            </a>
          </div>
        ) : null}
        <div className="form-group">
          <label style={{ fontWeight: 700 }}>Department Name</label>
          {isAddDepartmentType ? (
            <input type="text" className="form-control" onChange={(e) => setName(e.target.value)} />
          ) : (
            <input
              type="text"
              disabled={!isAddDepartmentType}
              className="form-control"
              value={name}
            />
          )}
        </div>
        <div className="form-group">
          <label style={{ fontWeight: 700 }}>Department Parent</label>
          {availableParentDepartments && (
            <select
              className="form-control"
              onChange={(e) => setParent(e.target.value)}
              defaultValue={availableParentDepartments.length === 0 ? "" : parent}
            >
              <>
                <option value="">None</option>
                {availableParentDepartments.length > 0 &&
                  availableParentDepartments.map((availableParent, index) => {
                    return (
                      <option
                        value={availableParent.DepartmentName}
                        key={`${index}-${availableParent.DepartmentName}`}
                      >
                        {availableParent.DepartmentName}
                      </option>
                    );
                  })}
              </>
            </select>
          )}
        </div>
        {!isAddDepartmentType && (
          <div className="form-group">
            <label style={{ fontWeight: 700 }}>New Department Name:</label>
            <input className="form-control" onChange={(e) => setNewName(e.target.value)} />
          </div>
        )}

        {errorMessage && (
          <div>
            <p style={{ color: "red" }}>{errorMessage}</p>
          </div>
        )}
        <div className="btns">
          <a className="btn" onClick={() => saveDepartment(name, parent, newName, type)}>
            Save
          </a>
          <a className="btn" onClick={() => onClose()}>
            Cancel
          </a>
        </div>
      </div>
    </td>
  );
};
