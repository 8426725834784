import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Button } from "@progress/kendo-buttons-react-wrapper";
import { DataLoadingSpinner } from "../../../../components/spinners";
import { DepartmentItem } from "./department-item";
import { AddDepartment } from "./add-department";
import { fetchData, post } from "../../../../requests/api_calls";

import "../../../../css/enterprise-settings/enterprise_settings.css";

class DepartmentModal extends Component {
  constructor(props) {
    super(props);

    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.state = {
      showModal: false,
      loading: false,
      departments: [],
    };
  }

  handleClose() {
    this.setState({ showModal: false });
  }

  async handleShow() {
    this.setState({ showModal: true, loading: true });
    const data = await this.fetchDepartments();
    this.setState({ departments: data, loading: false });
  }

  fetchDepartments = async () => {
    return await fetchData("admin/enterprise/GetDepartments").then(
      (departmentsData) => departmentsData
    );
  };

  componentDidMount() {}

  onSuccessDepartmentAction = async () => {
    this.setState({ loading: true });
    const updatedDepartments = await this.fetchDepartments();
    this.setState({ departments: updatedDepartments, loading: false });
  };

  cancelEditDepartment = () => {
    this.handleClose();
  };

  render() {
    let modalContent;
    const { departments, loading } = this.state;

    if (loading) {
      modalContent = (
        <div className="content">
          <DataLoadingSpinner
            className="spinner"
            style={{ margin: "10px auto 0", height: "40px" }}
          />
        </div>
      );
    } else if (departments.length > 0) {
      modalContent = (
        <div className="department-block">
          <table className="table">
            <thead>
              <tr style={{ backgroundColor: "#f6f6f6" }}>
                <td>
                  <b>Department Name</b>
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {departments.map((department, index) => {
                return (
                  <DepartmentItem
                    key={index}
                    department={department}
                    index={index}
                    onSuccessAddOrEdit={this.onSuccessDepartmentAction}
                  />
                );
              })}
              <AddDepartment onSuccessAddOrEdit={this.onSuccessDepartmentAction} />
            </tbody>
          </table>
        </div>
      );
    } else {
      modalContent = <></>;
    }

    return (
      <div className="setting">
        <span onClick={this.handleShow}>
          <span className="name">Departments</span>
          <a className="icon btn">
            <svg width="14" height="14" viewBox="0 0 24 24">
              <path
                d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                transform="scale(0.046875 0.046875)"
              ></path>
            </svg>
          </a>
        </span>
        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Departments</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
          <Modal.Footer>
            <div className="btns">
              <Button className="btn" click={this.handleClose}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default DepartmentModal;
