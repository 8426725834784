import { useEffect, useState } from "react";

import { ADD_EVENT, EDIT_EVENT, recurrenceValues } from "./constants";
import { fetchData, post } from "../../../../requests/api_calls";
import { DateTimePicker } from "@progress/kendo-dateinputs-react-wrapper";
import { DataLoadingSpinner } from "../../../../components/spinners";
import { formatDateToISOString, getErrorMessage } from "./utils";

export const EventItem = ({
  type,
  scheduleName,
  scheduleType,
  eventItem,
  selectItemToDelete,
  onAddEvent,
  onCancelAddOrEditEvent,
}) => {
  const isAddEventItem = type === ADD_EVENT;
  const eventItemId = isAddEventItem
    ? "addEvent"
    : `${eventItem?.EventName.replace(/[^\w\s]/g, "").replace(/\s+/g, "")}`;

  const [showItemDetails, setShowItemDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState(null);
  const [showRequired, setShowRequired] = useState(false);

  const [currentEventName, setCurrentEventName] = useState(eventItem?.EventName || "");
  const [newEventName, setNewEventName] = useState(eventItem?.EventName || "");
  const [recurValue, setRecurValue] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [allDayEvent, setAllDayEvent] = useState(false);
  const [dailyRecurrence, setDailyRecurrence] = useState();
  const [weeklyRecurrence, setWeeklyRecurrence] = useState();
  const [monthlyByDayRecurrence, setMonthlyByDayRecurrence] = useState();
  const [monthlyByWeekRecurrence, setMonthlyByWeekRecurrence] = useState();
  const [yearlyByDayRecurrence, setYearlyByDayRecurrence] = useState();
  const [yearlyByWeekRecurrence, setYearlyByWeekRecurrence] = useState();

  const [recurEndType, setRecurEndType] = useState(0);
  const [recurEndOccurrence, setRecurEndOccurrence] = useState();
  const [recurEndDate, setRecurEndDate] = useState(new Date());
  const [recurForEver, setRecurForEver] = useState(false);

  const [dayOfWeekRecurrence, setDayOfWeekRecurrence] = useState(0);
  const [weekOfMonthRecurrence, setWeekOfMonthRecurrence] = useState(0);
  const [monthRecurrence, setMonthRecurrence] = useState(0);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (event) {
      const {
        RecurrenceType,
        StartDate,
        StartTime,
        EndDate,
        EndTime,
        AllDayEvent,
        DailyRecurrence,
        WeeklyRecurrence,
        MonthlyByDayRecurrence,
        MonthlyByWeekRecurrence,
        YearlyByDayRecurrence,
        YearlyByWeekRecurrence,
        RecurEndType,
        RecurEndDate,
        RecurEndOccurrence,
        RecurForEver,
      } = event;

      let startDateAndTime = new Date(StartDate);
      startDateAndTime.setHours(StartTime?.Hour || 0);
      startDateAndTime.setMinutes(StartTime?.Minute || 0);
      let endDateAndTime = new Date(EndDate);
      endDateAndTime.setHours(EndTime?.Hour || 0);
      endDateAndTime.setMinutes(EndTime?.Minute || 0);
      let recurEndDateAndTime = new Date(RecurEndDate);
      recurEndDateAndTime.setHours(StartTime?.Hour || 0);
      recurEndDateAndTime.setMinutes(StartTime?.Minute || 0);

      setRecurValue(RecurrenceType);
      setStartDate(startDateAndTime);
      setEndDate(endDateAndTime);
      setAllDayEvent(AllDayEvent);
      setDailyRecurrence(DailyRecurrence);
      setWeeklyRecurrence(WeeklyRecurrence);
      setMonthlyByDayRecurrence(MonthlyByDayRecurrence);
      setRecurEndType(RecurEndType);
      setRecurEndDate(recurEndDateAndTime);
      setRecurEndOccurrence(RecurEndOccurrence);
      setRecurForEver(RecurForEver);

      if (MonthlyByWeekRecurrence) {
        setMonthlyByWeekRecurrence(MonthlyByWeekRecurrence);
        setDayOfWeekRecurrence(MonthlyByWeekRecurrence.DayOfWeek);
        setWeekOfMonthRecurrence(MonthlyByWeekRecurrence.DayOfWeekInMonth);
      }
      if (YearlyByDayRecurrence) {
        setYearlyByDayRecurrence(YearlyByDayRecurrence);
        setMonthRecurrence(YearlyByDayRecurrence.Month);
      }
      if (YearlyByWeekRecurrence) {
        setYearlyByWeekRecurrence(YearlyByWeekRecurrence);
        setDayOfWeekRecurrence(YearlyByWeekRecurrence.DayOfWeek);
        setWeekOfMonthRecurrence(YearlyByWeekRecurrence.DayOfWeekInMonth);
        setMonthRecurrence(YearlyByWeekRecurrence.Month);
      }
    }
  }, [event]);

  useEffect(() => {
    checkForEventItemForm();
  }, [
    newEventName,
    recurValue,
    recurEndType,
    dailyRecurrence,
    weeklyRecurrence,
    monthlyByDayRecurrence,
    monthlyByWeekRecurrence,
    yearlyByDayRecurrence,
    yearlyByWeekRecurrence,
  ]);

  const fetchEventItemDetail = async () => {
    const request = await fetchData(
      "admin/enterprise/GetTimeScheduleEvent" +
        `?scheduleName=${scheduleName}` +
        `&scheduleType=${scheduleType}` +
        `&eventName=${currentEventName}`
    ).then((data) => data);
    setEvent(request);
    setNewEventName(currentEventName);
  };

  /* Event Actions */
  const loadItemDetails = async () => {
    if (!showItemDetails) {
      setLoading(true);
      await fetchEventItemDetail();
      setLoading(false);
      setShowItemDetails(true);
    } else if (showItemDetails) {
      setShowItemDetails(false);
    }
  };

  const checkForEventItemForm = () => {
    if (!Boolean(newEventName) || !["number", "string"].includes(typeof recurValue)) {
      setShowRequired(true);
      return false;
    }

    switch (Number(recurValue)) {
      case 1:
        if (
          !dailyRecurrence?.Interval ||
          !(
            (recurEndType == "2" && recurEndOccurrence > 0 && recurEndOccurrence !== "") ||
            (recurEndType == "1" && recurEndDate) ||
            recurEndType == "0"
          )
        ) {
          setShowRequired(true);
          return false;
        }
        break;
      case 2:
        if (
          !weeklyRecurrence?.Interval ||
          !(
            (recurEndType == "2" && recurEndOccurrence > 0 && recurEndOccurrence !== "") ||
            (recurEndType == "1" && recurEndDate) ||
            recurEndType == "0"
          ) ||
          !(
            weeklyRecurrence.Monday ||
            weeklyRecurrence.Tuesday ||
            weeklyRecurrence.Wednesday ||
            weeklyRecurrence.Thursday ||
            weeklyRecurrence.Friday ||
            weeklyRecurrence.Saturday ||
            weeklyRecurrence.Sunday
          )
        ) {
          setShowRequired(true);
          return false;
        }
        break;
      case 3:
        if (
          !monthlyByDayRecurrence?.Interval ||
          !monthlyByDayRecurrence?.DayOfMonth ||
          !(
            (recurEndType == "2" && recurEndOccurrence > 0 && recurEndOccurrence !== "") ||
            (recurEndType == "1" && recurEndDate) ||
            recurEndType == "0"
          )
        ) {
          setShowRequired(true);
          return false;
        }
        break;
      case 4:
        if (
          !monthlyByWeekRecurrence?.Interval ||
          !(
            (recurEndType == "2" && recurEndOccurrence > 0 && recurEndOccurrence !== "") ||
            (recurEndType == "1" && recurEndDate) ||
            recurEndType == "0"
          )
        ) {
          setShowRequired(true);
          return false;
        }
        break;
      case 5:
        if (
          !yearlyByDayRecurrence?.Interval ||
          !yearlyByDayRecurrence?.DayOfMonth ||
          !(
            (recurEndType == "2" && recurEndOccurrence > 0 && recurEndOccurrence !== "") ||
            (recurEndType == "1" && recurEndDate) ||
            recurEndType == "0"
          )
        ) {
          setShowRequired(true);
          return false;
        }
        break;
      case 6:
        if (
          !yearlyByWeekRecurrence?.Interval ||
          !(
            (recurEndType == "2" && recurEndOccurrence > 0 && recurEndOccurrence !== "") ||
            (recurEndType == "1" && recurEndDate) ||
            recurEndType == "0"
          )
        ) {
          setShowRequired(true);
          return false;
        }
        break;
      default:
        break;
    }
    setShowRequired(false);
    return true;
  };

  const cancelAddOrEditEvent = () => {
    if (isAddEventItem) {
      onCancelAddOrEditEvent();
    } else {
      loadItemDetails();
    }
  };

  const saveEvent = async () => {
    const isFormAvailable = checkForEventItemForm();
    if (isFormAvailable) {
      const path = isAddEventItem
        ? "admin/enterprise/AddTimeScheduleEvent"
        : "admin/enterprise/UpdateTimeScheduleEvent";
      const data = {
        ScheduleName: scheduleName,
        ScheduleType: scheduleType,
        EventName: currentEventName || newEventName,
        NewEventName: !isAddEventItem && newEventName !== currentEventName ? newEventName : null,
        StartDate: formatDateToISOString(startDate),
        EndDate: formatDateToISOString(endDate),
        AllDayEvent: allDayEvent,
        StartTime: allDayEvent
          ? null
          : { Hour: startDate.getHours(), Minute: startDate.getMinutes() },
        EndTime: allDayEvent ? null : { Hour: endDate.getHours(), Minute: endDate.getMinutes() },
        RecurrenceType: recurValue || null,
        RecurEndType: recurEndType || null,
        RecurEndDate: recurEndDate || null,
        RecurEndOccurrence: recurEndOccurrence || null,
        RecurForEver: recurForEver || null,
        DailyRecurrence: dailyRecurrence || null,
        WeeklyRecurrence: weeklyRecurrence || null,
        MonthlyByDayRecurrence: monthlyByDayRecurrence || null,
        MonthlyByWeekRecurrence: monthlyByWeekRecurrence
          ? {
              ...monthlyByWeekRecurrence,
              DayOfWeekInMonth: weekOfMonthRecurrence,
              DayOfWeek: dayOfWeekRecurrence,
            }
          : null,
        YearlyByDayRecurrence: yearlyByDayRecurrence
          ? {
              ...yearlyByDayRecurrence,
              Month: monthRecurrence,
            }
          : null,
        YearlyByWeekRecurrence: yearlyByWeekRecurrence
          ? {
              ...yearlyByWeekRecurrence,
              DayOfWeekInMonth: weekOfMonthRecurrence,
              DayOfWeek: dayOfWeekRecurrence,
              Month: monthRecurrence,
            }
          : null,
      };

      try {
        setErrorMessage("");
        setLoading(true);
        const response = await post(path, "POST", JSON.stringify(data));
        if (response.ok) {
          setLoading(false);
          if (isAddEventItem) {
            await onAddEvent({ EventName: data.EventName });
          }
          if (!isAddEventItem) {
            setCurrentEventName(data.NewEventName || currentEventName);
          }
        } else {
          console.error(`${path}: failed: ${response.message}`);
          setLoading(false);
          setErrorMessage(getErrorMessage(response.message));
        }
      } catch (error) {
        console.error(`${path}: failed: ${error}`);
        setLoading(false);
        setErrorMessage(getErrorMessage(error));
      }
    }
  };

  /* End Event Actions */

  const changeRecurs = (event) => {
    setRecurValue(event.target.value);
  };

  const updateAllDayEventAndTime = (value) => {
    setAllDayEvent(value);
    if (value) {
      const newStartDate = new Date(startDate);
      newStartDate.setHours(0);
      newStartDate.setMinutes(0);
      newStartDate.setSeconds(0);
      setStartDate(newStartDate);
      const newEndDate = new Date(endDate);
      newEndDate.setHours(0);
      newEndDate.setMinutes(0);
      newEndDate.setSeconds(0);
      setEndDate(newEndDate);
    }
  };

  const getRecurrentControl = () => {
    const recurrenceValue = recurrenceValues[recurValue];

    switch (recurrenceValue) {
      case "Never":
        return <div key="never-option">{getTime()}</div>;
      case "Daily":
        return (
          <div key="daily-option">
            {getTime()}
            <div className="form-group">
              <label>Recur every how many days</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired && !dailyRecurrence?.Interval && "required"
                }`}
                value={dailyRecurrence?.Interval || ""}
                onChange={(e) => setDailyRecurrence({ Interval: e.target.value })}
              />
              {showRequired && !dailyRecurrence?.Interval && (
                <span style={{ fontSize: "10px", color: "red" }}>Required</span>
              )}
            </div>
            {getRecurEnd()}
          </div>
        );
      case "Weekly":
        return (
          <div key="weekly-option">
            {getTime()}
            <div className="form-group">
              <label>Recur every how many weeks</label>
              <input
                type="type"
                className="form-control"
                value={weeklyRecurrence?.Interval || ""}
                onChange={(e) =>
                  setWeeklyRecurrence({
                    ...weeklyRecurrence,
                    Interval: e.target.value,
                  })
                }
              />
              {showRequired && !weeklyRecurrence?.Interval && (
                <span style={{ fontSize: "10px", color: "red" }}>Required</span>
              )}
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Monday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Monday: e.target.checked,
                    })
                  }
                />{" "}
                Monday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Tuesday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Tuesday: e.target.checked,
                    })
                  }
                />{" "}
                Tuesday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Wednesday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Wednesday: e.target.checked,
                    })
                  }
                />{" "}
                Wednesday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Thursday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Thursday: e.target.checked,
                    })
                  }
                />{" "}
                Thursday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Friday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Friday: e.target.checked,
                    })
                  }
                />{" "}
                Friday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Saturday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Saturday: e.target.checked,
                    })
                  }
                />{" "}
                Saturday
              </label>
            </div>
            <div className="form-group">
              <label className="">
                <input
                  type="checkbox"
                  checked={weeklyRecurrence?.Sunday}
                  onChange={(e) =>
                    setWeeklyRecurrence({
                      ...weeklyRecurrence,
                      Sunday: e.target.checked,
                    })
                  }
                />{" "}
                Sunday
              </label>
            </div>
            {getRecurEnd()}
          </div>
        );
      case "MonthlyByDay":
        return (
          <div key="monthly-by-day-option">
            {getTime()}
            <div className="form-group">
              <label>Recur every how many months</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired && !monthlyByDayRecurrence?.Interval && "required"
                }`}
                value={monthlyByDayRecurrence?.Interval || ""}
                onChange={(e) =>
                  setMonthlyByDayRecurrence({
                    ...monthlyByDayRecurrence,
                    Interval: e.target.value,
                  })
                }
              />
              {showRequired && !monthlyByDayRecurrence?.Interval && (
                <span style={{ fontSize: "10px", color: "red" }}>Required</span>
              )}
            </div>
            <div className="form-group">
              <label>Numerical day of the month:</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired && !monthlyByDayRecurrence?.DayOfMonth && "required"
                }`}
                value={monthlyByDayRecurrence?.DayOfMonth || ""}
                onChange={(e) =>
                  setMonthlyByDayRecurrence({
                    ...monthlyByDayRecurrence,
                    DayOfMonth: e.target.value,
                  })
                }
              />
              {showRequired && !monthlyByDayRecurrence?.DayOfMonth && (
                <span style={{ fontSize: "10px", color: "red" }}>Required</span>
              )}
            </div>
            {getRecurEnd()}
          </div>
        );
      case "MonthlyByWeek":
        return (
          <div key="monthly-by-week-option">
            {getTime()}
            <div className="form-group">
              <label>Recur every how many months</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired && !monthlyByWeekRecurrence?.Interval && "required"
                }`}
                value={monthlyByWeekRecurrence?.Interval || ""}
                onChange={(e) =>
                  setMonthlyByWeekRecurrence({
                    ...monthlyByWeekRecurrence,
                    Interval: e.target.value,
                  })
                }
              />
              {showRequired && !monthlyByWeekRecurrence?.Interval && (
                <span style={{ fontSize: "10px", color: "red" }}>Required</span>
              )}
            </div>
            <div className="form-group">
              <label>Week of the month</label>
              {getRecurWeekOfMonths()}
            </div>
            <div className="form-group">
              <label>Day of the week:</label>
              {getRecurDays()}
            </div>
            {getRecurEnd()}
          </div>
        );
      case "YearlyByDay":
        return (
          <div key="yearly-by-day-option">
            {getTime()}
            <div className="form-group">
              <label>Recur every how many years</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired &&
                  (!yearlyByDayRecurrence?.Interval || yearlyByDayRecurrence?.Interval === "") &&
                  "required"
                }`}
                value={yearlyByDayRecurrence?.Interval || ""}
                onChange={(e) =>
                  setYearlyByDayRecurrence({
                    ...yearlyByDayRecurrence,
                    Interval: e.target.value,
                  })
                }
              />
              {showRequired &&
                (!yearlyByDayRecurrence?.Interval || yearlyByDayRecurrence?.Interval === "") && (
                  <span style={{ fontSize: "10px", color: "red" }}>Required</span>
                )}
            </div>
            <div className="form-group">
              <label>The month</label>
              {getRecurMonths()}
            </div>
            <div className="form-group">
              <label>Numerical day of the month:</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired &&
                  (!yearlyByDayRecurrence?.DayOfMonth ||
                    yearlyByDayRecurrence?.DayOfMonth === "") &&
                  "required"
                }`}
                value={yearlyByDayRecurrence?.DayOfMonth || ""}
                onChange={(e) =>
                  setYearlyByDayRecurrence({
                    ...yearlyByDayRecurrence,
                    DayOfMonth: e.target.value,
                  })
                }
              />
              {showRequired &&
                (!yearlyByDayRecurrence?.DayOfMonth ||
                  yearlyByDayRecurrence?.DayOfMonth === "") && (
                  <span style={{ fontSize: "10px", color: "red" }}>Required</span>
                )}
            </div>
            {getRecurEnd()}
          </div>
        );
      case "YearlyByWeek":
        return (
          <div key="yearly-by-week-option">
            {getTime()}
            <div className="form-group">
              <label>Recur ever how many years</label>
              <input
                type="type"
                className={`form-control ${
                  showRequired &&
                  (!yearlyByWeekRecurrence?.Interval || yearlyByWeekRecurrence?.Interval === "") &&
                  "required"
                }`}
                value={yearlyByWeekRecurrence?.Interval || ""}
                onChange={(e) =>
                  setYearlyByWeekRecurrence({
                    ...yearlyByWeekRecurrence,
                    Interval: e.target.value,
                  })
                }
              />
              {showRequired &&
                (!yearlyByWeekRecurrence?.Interval || yearlyByWeekRecurrence?.Interval === "") && (
                  <span style={{ fontSize: "10px", color: "red" }}>Required</span>
                )}
            </div>
            <div className="form-group">
              <label>The month</label>
              {getRecurMonths()}
            </div>
            <div className="form-group">
              <label>Week of the month</label>
              {getRecurWeekOfMonths()}
            </div>
            <div className="form-group">
              <label>Day of the week:</label>
              {getRecurDays()}
            </div>
            {getRecurEnd()}
          </div>
        );
    }
  };

  const getTime = () => {
    return (
      <div className="form-group">
        <label className="switch">
          <input
            type="checkbox"
            checked={allDayEvent}
            onChange={(e) => updateAllDayEventAndTime(e.target.checked)}
          />
          <span className="slider round"></span>
        </label>
        All Day Event
        <div className="show">
          <div className="form-group">
            <label>Start Date Time</label>
            <DateTimePicker
              value={startDate}
              format="yyyy/MM/dd hh:mm tt"
              change={(e) => setStartDate(e.sender.value())}
              interval={1}
            />
          </div>
          <div className="form-group">
            <label>End Date Time</label>
            <DateTimePicker
              value={endDate}
              format="yyyy/MM/dd hh:mm tt"
              change={(e) => setEndDate(e.sender.value())}
              interval="1"
            />
          </div>
        </div>
      </div>
    );
  };

  const changeRecurEndType = (event) => {
    const { value } = event.target;
    if (value === 0) {
      setRecurForEver(true);
    } else {
      setRecurForEver(false);
    }
    setRecurEndType(value);
  };

  const getRecurEnd = () => {
    return (
      <>
        <div className="form-group">
          <label>End</label>
          <select className="form-control" value={recurEndType} onChange={changeRecurEndType}>
            <option value="0">Never</option>
            <option value="2">After</option>
            <option value="1">Date</option>
          </select>
        </div>
        {recurEndType == "2" && (
          <div className="form-group">
            <label>After</label>
            <input
              type="type"
              className={`form-control ${showRequired && !recurEndOccurrence && "required"}`}
              value={recurEndOccurrence}
              onChange={(e) => setRecurEndOccurrence(e.target.value)}
            />
            <span>Occurrences</span>
            {showRequired && !recurEndOccurrence && (
              <div>
                <span style={{ fontSize: "10px", color: "red" }}>Required</span>
              </div>
            )}
          </div>
        )}
        {recurEndType == "1" && (
          <div className="form-group">
            <label>Date</label>
            <DateTimePicker
              className={`${showRequired && !recurEndDate && "required"}`}
              value={recurEndDate}
              format="yyyy/MM/dd hh:mm tt"
              change={(e) => setRecurEndDate(e.sender.value())}
              interval={1}
            />
            {showRequired && !recurEndDate && (
              <span style={{ fontSize: "10px", color: "red" }}>Required</span>
            )}
          </div>
        )}
      </>
    );
  };

  const getRecurMonths = () => {
    return (
      <select
        className="form-control"
        value={monthRecurrence}
        onChange={(e) => setMonthRecurrence(e.target.value)}
      >
        <option value="0">January</option>
        <option value="1">February</option>
        <option value="2">March</option>
        <option value="3">April</option>
        <option value="4">May</option>
        <option value="5">June</option>
        <option value="6">July</option>
        <option value="7">August</option>
        <option value="8">September</option>
        <option value="9">October</option>
        <option value="10">November</option>
        <option value="11">December</option>
      </select>
    );
  };

  const getRecurDays = () => {
    return (
      <select
        className="form-control"
        value={dayOfWeekRecurrence}
        onChange={(e) => setDayOfWeekRecurrence(e.target.value)}
      >
        <option value="0">Sunday</option>
        <option value="1">Monday</option>
        <option value="2">Tuesday</option>
        <option value="3">Wednesday</option>
        <option value="4">Thursday</option>
        <option value="5">Friday</option>
        <option value="6">Saturday</option>
      </select>
    );
  };

  const getRecurWeekOfMonths = () => {
    return (
      <select
        className="form-control"
        value={weekOfMonthRecurrence}
        onChange={(e) => setWeekOfMonthRecurrence(e.target.value)}
      >
        <option value="0">First</option>
        <option value="1">Second</option>
        <option value="2">Third</option>
        <option value="3">Fourth</option>
        <option value="4">Last</option>
      </select>
    );
  };

  const selectItemCheckbox = (e, eventItem) => {
    const deleteEventData = {
      EventName: currentEventName,
      ScheduleName: scheduleName,
      ScheduleType: scheduleType,
    };
    selectItemToDelete(e.target.checked, deleteEventData);
  };

  return (
    <>
      {type === EDIT_EVENT && (
        <tr>
          <td className="check">
            <input type="checkbox" onClick={(e) => selectItemCheckbox(e, eventItem)} />
          </td>
          <td>{currentEventName}</td>
          <td align="right">
            <a className="icon btn" onClick={() => loadItemDetails()}>
              <svg width="14" height="14" viewBox="0 0 24 24">
                <path
                  d="m163 440l-91-91 251-250 90 90z m309-352l-48-48c-12-11-32-11-45 2l-45 45 91 91 45-45c13-13 13-33 2-45z m-408 275l-32 117 117-32z"
                  transform="scale(0.046875 0.046875)"
                ></path>
              </svg>
            </a>
          </td>
        </tr>
      )}
      {loading && (
        <tr>
          <td colSpan={4}>
            <div className="content">
              <DataLoadingSpinner
                className="spinner"
                style={{ margin: "10px auto 0", height: "40px" }}
              />
            </div>
          </td>
        </tr>
      )}
      {!loading && (showItemDetails || isAddEventItem) && (
        <tr id={eventItemId}>
          <td colSpan="4">
            <div className="table-edit">
              <div className="form-group">
                <label>Event Name</label>
                <input
                  type="type"
                  className={`form-control ${showRequired && !newEventName && "required"}`}
                  value={newEventName}
                  onChange={(e) => setNewEventName(e.target.value)}
                />
                {showRequired && !newEventName && (
                  <span style={{ fontSize: "10px", color: "red" }}>Required</span>
                )}
              </div>
              <div className="form-group">
                <label>Recurs</label>
                <select
                  className={`form-control ${showRequired && !recurValue && "required"}`}
                  onChange={changeRecurs}
                  value={recurValue}
                >
                  <option value="">-Select-</option>
                  <option value="0">Never</option>
                  <option value="1">Daily</option>
                  <option value="2">Weekly</option>
                  <option value="3">Monthly by Day</option>
                  <option value="4">Monthly by Week</option>
                  <option value="5">Yearly by Day</option>
                  <option value="6">Yearly by Week</option>
                </select>
                {showRequired && !recurValue && (
                  <span style={{ fontSize: "10px", color: "red" }}>Required</span>
                )}
              </div>
              {recurValue && getRecurrentControl()}
              <div className="btns">
                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                <button className="btn" onClick={saveEvent}>
                  Save Event
                </button>
                <button className="btn" onClick={cancelAddOrEditEvent}>
                  Cancel
                </button>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  );
};
