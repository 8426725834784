import React, { Component } from "react";
import { connect } from "react-redux";
import { tokenUpdater } from "./scripts/session";
import { BrowserRouter } from "react-router-dom";
import Navbar from "./containers/navigation/navbar";
import Sidebar from "./containers/navigation/sidebar";
import Footer from "./components/footer";
import Login from "./containers/login";
import FullStoryScript from "./scripts/full_story";
import * as helper from "./scripts/helper";
import * as session from "./scripts/session";
import * as config from "./requests/config";
import * as apiCalls from "./requests/api_calls";
import * as settings from "./scripts/settings";
import IdleLogout from "./components/idle_logout";
import "prop-types";
import Timer from "./thirdparty/react-timer-component/lib/Timer";
import { parse } from "query-string";
import { DataLoadingSpinner } from "./components/spinners";
import momentumLogoWhite from "./images/momentum_logo_white.png";
import momentumLogoSidebar from "./images/momentum_logo.png";
import TestResultModal from "./containers/modals/testResultModal";
import cssVars from "css-vars-ponyfill";
import { tokenRepo } from "./store/redux";
import SupportLinksPage from "./containers/noAuth/supportLinks";
import * as actions from "./store/actions";
import MyLogin from "./containers/login/login";
import ResetPassword from "./containers/login/resetPassword";
import ForgotPassword from "./containers/login/forgotPassword";
import MFAValidateModal from "./containers/login/modals/validateMFAmodal";
import ValidateMfaEmail from "./containers/login/mfaEmailValidateRedirect";
import MFAAuthModal from "./containers/login/modals/mfaModal";
import { Modal } from 'react-bootstrap';

class App extends Component {
  constructor(props) {
    super(props);

    this.wholesaleIndicatorFound = false;
    this.sideBarLogo = null;
    this.loadingLogo = null;
    this.loadingAltText = null;
    this.state = {
      RPXLoginFail: false,
      rpxLogin: false,
      rpxLoginDetailsRetrieved: false,
      key: 1,
      customTheme: true,
      customThemeLoaded: false,
      faviconApplied: false,
      rpxLogo: null,
      loadingLogo: null,
      loadingLogoAltText: "",
      loadingAccessToken: true,
      isMspLogin: false,
      validMfa: false,
      isRpXUnauthModalVisible: false,
      rpxErrorMsg: "",
    };
  }

  processCustomizations = async () => {
    this.wholesaleIndicatorFound = true;
    try {
      let hostName =
        window.location.host === "localhost:44316"
          ? process.env.REACT_APP_TEST_DOMAIN
          : window.location.host;
      let customizations = await config.retrieveCustomConfigs(hostName);
      let LogoLarge, LogoSmall, VendorId;
      if (!customizations) {
        const staticJSONPrefix = helper.getJSONFilePrefix();
        if (staticJSONPrefix) {
          const filename = `${staticJSONPrefix}-UI-${process.env.REACT_APP_ENVIRONMENT}`;
          try {
            customizations = await import(
              `../public/data/UISettings/${filename}`
            );
          } catch (e) {
            console.log("Error parsing JSON file");
          }
        }
      }
      LogoLarge = customizations.LogoLarge;
      VendorId = customizations.VendorId;
      LogoSmall = customizations.LogoSmall;

      this.loadingLogo = LogoLarge || momentumLogoWhite;
      this.sideBarLogo = LogoSmall || momentumLogoSidebar;
      helper.storeCustomImages(this.sideBarLogo, this.loadingLogo);
      this.loadingAltText = LogoLarge ? "Logo" : "Momentum Telecom Logo";
      const isApplied = await helper.processWholesaleCustomizations(
        customizations,
        this.sideBarLogo,
        this.loadingLogo
      );
      if (!customizations.CSS) await this.SetDefaultCSS();
      if (isApplied) {
        this.setState({ customTheme: true, customThemeLoaded: true });
      } else {
        this.setDefaultLogos();
      }
    } catch (e) {
      this.setDefaultLogos();
    }
    this.reducerMethod(actions.UI_SKINS_APPLIED);
  };

  setDefaultLogos = () => {
    this.sideBarLogo = momentumLogoSidebar;
    this.loadingLogo = momentumLogoWhite;
    this.loadingAltText = "Momentum Telecom Logo";
    helper.storeCustomImages(null, this.loadingLogo);
    this.setState({ customTheme: false });
  };

  checkWholesale = async () => {
    const { customTheme } = this.state;

    if (helper.shouldApplyCustomizations()) {
      this.processCustomizations();
    } else if (customTheme) {
      this.loadingLogo = momentumLogoWhite;
      this.setState({ customTheme: false });
    } else {
      this.SetDefaultCSS();
    }
  };

  SetDefaultCSS = async () => {
    //for internet explorer have to apply polyfills or will end up with no styling
    const filename = `Retail-CSS.json`;
    const styles = await import(`../public/data/UISettings/${filename}`);
    let variables = {};

    Object.keys(styles).forEach((style) => {
      let val = styles[style];
      variables[`--theme-${style}`] = val;
    });

    cssVars({ variables });
  };
  componentDidUpdate() {
    if (!this.wholesaleIndicatorFound) {
      this.checkWholesale();
    }
  }

  componentWillReceiveProps(props) {
    console.log("PROPS____", props);
    if (
      props.loadingAccessToken !== this.state.loadingAccessToken ||
      props.loggedIn !== this.state.loggedIn ||
      props.beta_access_denied !== this.state.beta_access_denied ||
      props.access_denied !== this.state.access_denied ||
      props.isMspLogin !== this.state.isMspLogin ||
      props.validMfa !== this.state.validMfa ||
      props.access_denied !== this.state.access_denied
    ) {
      this.setState({
        loadingAccessToken: props.loadingAccessToken,
        loggedIn: props.loggedIn,
        requestToken: props.requestToken,
        beta_access_denied: props.beta_access_denied,
        access_denied: props.access_denied,
        isMspLogin: props.isMspLogin,
        validMfa: props.validMfa,
        access_denied: props.access_denied
      });
    }
  }

  componentDidMount() {
    try {
      if (
        !helper.urlIncludes("ext_authenticate") &&
        !sessionStorage.getItem("loggingOut") &&
        !sessionStorage.getItem("emulatedUser")
      ) {
        this.reducerMethod();
      } else {
        this.setState({ loadingAccessToken: false });
      }
    } catch (error) {
      console.log("Error componentWillMount: " + error.toString());
    } finally {
      // ///////////////////////////////
      // console.log("----GetEnterpriseLevelMFA----START");
      // apiCalls.fetchData('admin/GetEnterpriseLevelMFA').then(data => {
      //   console.log("----GetEnterpriseLevelMFA", data);
      //   this.setState({ MFAOption: data });
      // }).catch(err => console.error(err));
      // ///////////////////////////////
      this.checkWholesale();
      helper.checkPageTitle();
      if (helper.urlIncludes("ext_authenticate")) {
        this.authorizeRpxSession();
        this.retrieveRpxLogo();
      }
    }
  }

  reducerMethod = (actionName = "") => this.props.tokenRepo(actionName);

  retrieveRpxLogo = async () => {
    const b2cResults = await helper.getB2CConfigResults(helper.getDomain());
    if (b2cResults) {
      const { LogoLarge, VendorId } = b2cResults;
      helper.storeVendorId(VendorId);
      this.setState({ rpxLogo: LogoLarge });
    } else {
      this.SetDefaultCSS();
    }
  };

  authorizeRpxSession = async (e) => {
    try {
      let encryptedParams = parse(window.location.search).params;
      let emulationUserParam = parse(window.location.search).EUN;
      let userParam = decodeURIComponent(parse(window.location.search).UN);
      let uidParam = parse(window.location.search).EUID;
      let ipParam = parse(window.location.search).IP;
      if (ipParam && ipParam.toString() === "1")
        sessionStorage.setItem("IPTrunkEmulation", true);
      //retrieve details for fullstory -> sessionStorage.userEmail + '-' + sessionStorage.sessionStart
      sessionStorage.setItem("sessionStart", new Date().getTime());
      sessionStorage.setItem("RPXLogin", true);
      sessionStorage.setItem("emulatedUser", emulationUserParam);
      sessionStorage.setItem("userEmail", userParam);
      sessionStorage.setItem("userId", uidParam);
      await this.setState({ rpxLoginDetailsRetrieved: true });
      const rpxParams = {
        params: encryptedParams,
        EUN: emulationUserParam,
        UN: userParam,
        EUID: uidParam,
      };
      try {
        await config.loginRPXUser(rpxParams);
      } catch (error) {
        console.log(error)
        if (error.message?.includes( "This user is not authorized for portal access")) {
          this.setState({
            isRpXUnauthModalVisible: true,
            rpxErrorMsg: error.message,
          });
        } else if (error.message === "token not valid") {
          console.log("Retrying AzureToken API call due to failure");
          authorizeRpxSession();
        } else {
          this.setState({ RPXLoginFail: true });
        }
        console.log(error.message);
      }
    } catch (error) {
      this.setState({ RPXLoginFail: true });
      console.log(error.message);
    }
  };

  onIdle = () => session.logout();

  updateToken = (e) => {
    setTimeout(() => {
      tokenUpdater(true);
      this.setState({ ...this.state, key: this.state.key + 1 });
    }, 3000);
  };

  render() {
    let tokenIsValid = sessionStorage.tokenIsValid || false;
    let domain = helper.getDomain();
    //if (this.state.RPXLoginFail)
    let loginErrorHTML = (
      <div className="login-error container">
        <div className="summary">
          <div className="error">
            <h3>Error</h3>
            <div>Login Failed</div>
          </div>
          <a
            href="/session"
            className="btn btn-block btn-primary"
            onClick={session.logout}
            alt="restart login button"
          >
            Restart Login
          </a>
        </div>
      </div>
    );
    const { rpxLogo } = this.state;

    let RPXLogo = (
      <div className="redirect container">
        <img src={rpxLogo} className="App-logo" alt="Logo" />
        <DataLoadingSpinner className="redirect spinner" />
      </div>
    );
    if (helper.urlIncludes("ext_authenticate")) {
      if (this.state.rpxLoginDetailsRetrieved === true) {
        if (this.state.RPXLoginFail)
          return (
            <div>
              <FullStoryScript />
              {loginErrorHTML}
            </div>
          );
          else if(this.state.isRpXUnauthModalVisible){
            return(
                <Modal
                  show={this.state.isRpXUnauthModalVisible}
                  onHide={this.handleClose}
                  dialogClassName="circuits-modal"
                  bsSize="large"
                >
                  <Modal.Header>
                    <Modal.Title>Access Denied</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {this.state.rpxErrorMsg}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <hr />
                    <div className="btns">
                      <span>
                        <button
                          className="btn"
                          onClick={() =>
                            this.setState({ isRpXUnauthModalVisible: false,RPXLoginFail:true })
                          }
                        >
                          Close
                        </button>
                      </span>
                    </div>
                  </Modal.Footer>
                </Modal>
              );
          }
        else if (rpxLogo) {
          return (
            <div>
              <FullStoryScript />
              {RPXLogo}
            </div>
          );
        } else {
          return (
            <div>
              <FullStoryScript />
            </div>
          );
        }
      } else {
        if (this.state.isRpXUnauthModalVisible) {
          return (
            <Modal
              show={this.state.isRpXUnauthModalVisible}
              onHide={this.handleClose}
              dialogClassName="circuits-modal"
              bsSize="large"
            >
              <Modal.Header>
                <Modal.Title>Access Denied</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {this.state.rpxErrorMsg}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <hr />
                <div className="btns">
                  <span>
                    <button
                      className="btn"
                      onClick={() =>
                        this.setState({ isRpXUnauthModalVisible: false })
                      }
                    >
                      Close
                    </button>
                  </span>
                </div>
              </Modal.Footer>
            </Modal>
          );
        }
        if (this.state.RPXLoginFail) {
          return { loginErrorHTML };
        } else if (rpxLogo) {
          {
            RPXLogo;
          }
        } else return null;
      }
    } else if (this.props.beta_access_denied) {
      let primaryUrl = helper.getProductionUrl();
      return (
        <div className="login-error container">
          {/* <FullStoryScript /> */}
          <div className="summary">
            <div className="error">
              <h3>Access to Beta Denied</h3>
              <div>
                This account has not been granted access to the Beta
                Environment. Click on the link below to be redirected to the
                Cloud Services Portal
              </div>
            </div>
            <a
              href={primaryUrl}
              className="btn btn-block btn-primary"
              onClick={session.logout}
              alt="restart login button"
            >
              Subscriber Portal
            </a>
            <div style={{ textAlign: "center" }}>
              <a
                href="/session"
                onClick={session.logout}
                alt="restart login button"
              >
                Login with another account
              </a>
            </div>
          </div>
        </div>
      );
    } else if (this.props.access_denied) {
      return (
        <div className="login-error container">
          {/* <FullStoryScript /> */}
          <div className="summary">
            <div className="error">
              <h3>Access Denied</h3>
              <div>
              This account is unauthorized to access.
              </div>
            </div>
            <div style={{ textAlign: "center" }}>
              <a
                onClick={session.logout}
                alt="restart login button"
              >
                Login with another account
              </a>
            </div>
          </div>
        </div>
      );
    }
      else if (
      this.props.loggedIn ||
      (sessionStorage.loggedIn && tokenIsValid)
    ) {
      if (
        (this.state.customTheme && this.state.customThemeLoaded) ||
        !this.state.customTheme
      ) {
        // ** Start MFA code
        // read enterprise option; if set to 'Mandatory' (and no verification type) open MFA modal
        if (
          sessionStorage.enableMFA == "true" &&
          sessionStorage.MFAOption === "Mandatory" &&
          !sessionStorage.verificationMethod &&
          (!sessionStorage.validMfa ||
            config.decryptKey(sessionStorage.validMfa) == false)
        ) {
          if (
            !sessionStorage.RPXLogin ||
            sessionStorage.RPXLogin == false ||
            sessionStorage.RPXLogin == "false"
          ) {
            return <MFAAuthModal />;
          }
        }
        if (
          sessionStorage.enableMFA == "true" &&
          sessionStorage.verificationMethod &&
          (!sessionStorage.validMfa ||
            config.decryptKey(sessionStorage.validMfa) == false)
        ) {
          // disable for users emulating from RPX
          if (
            !sessionStorage.RPXLogin ||
            sessionStorage.RPXLogin == false ||
            sessionStorage.RPXLogin == "false"
          ) {
            return <MFAValidateModal />;
          }
        }

        // ** End MFA code
        return (
          <div>
            <Timer
              key={this.state.key}
              remaining={config.expirationRemainingDelta()}
              afterComplete={this.updateToken}
            />
            <IdleLogout
              logoutTime={settings.idleLogoutTime}
              logoutDelay={settings.idleLogoutWarning}
            />
            <FullStoryScript />
            <TestResultModal />
            <BrowserRouter>
              <div>
                <Navbar />
                {((helper.shouldApplyCustomizations() && this.sideBarLogo) ||
                  !helper.shouldApplyCustomizations()) && (
                  <Sidebar logo={this.sideBarLogo} />
                )}
                <Footer />
              </div>
            </BrowserRouter>
            {tokenUpdater(this.props.requestToken)}
          </div>
        );
      } else {
        const loadingLogo = session.customLogoLarge() || momentumLogoWhite;
        return (
          <div className="redirect container">
            {/* <img src={loadingLogo} className="App-logo" alt={this.loadingAltText} /> */}
            {/* <FullStoryScript /> */}
            <DataLoadingSpinner className="redirect spinner" />
          </div>
        );
      }
    } else if (helper.atSupportLinksPath() && !helper.isRetailDomain(domain)) {
      if (
        (this.state.customTheme && this.state.customThemeLoaded) ||
        !this.state.customTheme
      ) {
        return (
          <div>
            <BrowserRouter>
              <div>
                {/* {((helper.shouldApplyCustomizations() && this.sideBarLogo) || !helper.shouldApplyCustomizations())
                  && <Sidebar logo={this.sideBarLogo} />
                } */}
                <SupportLinksPage />
                <Footer />
              </div>
            </BrowserRouter>
          </div>
        );
      } else {
        const loadingLogo = session.customLogoLarge() || momentumLogoWhite;
        return (
          <div className="redirect container">
            {/* <img src={loadingLogo} className="App-logo" alt={this.loadingAltText} /> */}
            {/* <FullStoryScript /> */}
            <DataLoadingSpinner className="redirect spinner" />
          </div>
        );
      }
    } else if (!this.state.loadingAccessToken && !tokenIsValid) {
      if (window.location.pathname == "/forgotpassword") {
        return (
          <div>
            <BrowserRouter>
              <ForgotPassword />
            </BrowserRouter>
          </div>
        );
      } else if (window.location.pathname == "/resetpassword") {
        return (
          <div>
            <BrowserRouter>
              <ResetPassword />
            </BrowserRouter>
          </div>
        );
      } else if (window.location.pathname == "/ValidateTwoStepEmail") {
        return (
          <div>
            <BrowserRouter>
              <ValidateMfaEmail />
            </BrowserRouter>
          </div>
        );
      } else {
        // session.logout()
        return (
          <div>
            <BrowserRouter>
              <MyLogin />
            </BrowserRouter>
          </div>
        );
      }
    } else {
      const loadingLogo = session.customLogoLarge() || momentumLogoWhite;
      return (
        <div className="redirect container">
          {/* <FullStoryScript /> */}
          <img
            src={loadingLogo}
            className="App-logo"
            alt={this.loadingAltText}
          />
          <DataLoadingSpinner className="redirect spinner" />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => {
  return {
    loadingAccessToken: state.reducer.loadingAccessToken,
    loggedIn: state.reducer.loggedIn,
    requestToken: state.reducer.requestToken,
    beta_access_denied: helper.toBoolean(state.reducer.beta_access_denied),
    rpxLogin: state.reducer.rpxLogin,
    isMspLogin: state.reducer.isMspLogin,
    redirecting: helper.toBoolean(state.reducer.redirecting),
    validMfa: state.reducer.validMfa,
    access_denied: helper.toBoolean(state.reducer.access_denied),
  };
};
const mapDispatchToProps = { tokenRepo };

export default connect(mapStateToProps, mapDispatchToProps)(App);